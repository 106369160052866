import { MenuItem, Select, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { lazy, array, number, object, string } from 'yup';
import { AppBadge } from '../../../components';

const currencies = ['GBP', 'EUR', 'USD'];

const numberWithEmptyStringAllowedbuilder = numberSchemaModificator => {
  return lazy(value => {
    if (value === '') {
      return string();
    }

    const schema = number();

    if (numberSchemaModificator) return numberSchemaModificator(schema);

    return schema;
  });
};

const amountSchema = object({
  rateAmount: numberWithEmptyStringAllowedbuilder(schema =>
    schema
      .optional()
      .nullable(true)
      .min(0, 'must be positive or zero')
      .max(100, '0-100 range allowed')
  ),
  fixedAmount: numberWithEmptyStringAllowedbuilder(schema =>
    schema
      .optional()
      .nullable(true)
      .min(0, 'must be positive or zero')
  ),
  minAmount: numberWithEmptyStringAllowedbuilder(schema =>
    schema
      .optional()
      .nullable(true)
      .min(0, 'must be positive or zero')
  ),
  maxAmount: numberWithEmptyStringAllowedbuilder(schema =>
    schema
      .optional()
      .nullable(true)
      .min(0, 'must be positive or zero')
  ),
  currency: string()
    .oneOf(currencies, 'please select')
    .required()
});

const amountArraySchema = array().of(amountSchema);

const FeeAmounts = props => {
  const amounts = [
    {
      fixedAmount: props.fee?.fixedAmount,
      rateAmount: props.fee?.rateAmount,
      minAmount: props.fee?.minAmount,
      maxAmount: props.fee?.maxAmount,
      currency: props.fee?.currency
    },
    ...(props.fee?.configuration?.secondaryAmounts // [{}]
      ? props.fee?.configuration?.secondaryAmounts
      : [])
  ];

  const setAmounts = async newAmounts => {
    await validateExistingAmounts();
    props.onChange(newAmounts);
  };

  const [errors, setErrors] = useState([]);

  const validateExistingAmounts = async () => {
    setErrors([]);
    // Validate existing values
    try {
      await amountArraySchema.validate(amounts, {
        abortEarly: false
      });
    } catch (e) {
      const innerErrors = e.inner;
      setErrors(
        innerErrors.map(x => ({
          field: x.path,
          message: x.message
        }))
      );
      return false;
    }

    const addedCurrency = amounts.map(x => x.currency);

    if (addedCurrency.length === currencies.length) {
      return false;
    }

    return true;
  };

  const addNewAmount = async () => {
    if (await validateExistingAmounts()) {
      setAmounts([
        ...amounts,
        {
          fixedAmount: ''
        }
      ]);
    }
  };

  const removeItem = (item, index) => {
    const newAmounts = JSON.parse(JSON.stringify(amounts));
    setAmounts([
      ...newAmounts.slice(0).filter((x, ind) => {
        return ind !== index;
      })
    ]);
  };

  const handleInputChange = async (item, index, field, value) => {
    const newItems = amounts.map((x, i) => {
      if (i === index) {
        return {
          ...x,
          [field]: value
        };
      }

      return x;
    });

    setAmounts(newItems);
  };

  return (
    <div className="fee-amounts">
      <h6>Fee amounts </h6>
      <table className="table compact-table compact-table-align-top">
        <thead>
          <tr>
            <th width="17%">Fixed amount</th>
            <th width="17%">Rate amount</th>
            <th width="17%">Min amount</th>
            <th width="17%">Max amount</th>
            <th width="17%">Currency</th>
            <th width="15%">Actions</th>
          </tr>
        </thead>
        <tbody>
          {amounts.map((x, index) => (
            <AmountRow
              onChange={(item, field, value) =>
                handleInputChange(item, index, field, value)
              }
              item={x}
              index={index}
              removeItem={item => removeItem(item, index)}
              errors={errors}
            />
          ))}
        </tbody>
      </table>
      <div className="text-center" style={{ width: '100%' }}>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => addNewAmount()}
        >
          Add
        </button>
      </div>
    </div>
  );
};

const AmountRow = props => {
  const {
    errors,
    index,
    item: { minAmount, maxAmount, fixedAmount, rateAmount, currency }
  } = props;

  const getErrorProps = (allErrors, field) => {
    const fieldErrors = allErrors?.filter(x => x.field === field);

    if (!fieldErrors || fieldErrors.length === 0) return null;

    return {
      helperText: fieldErrors.map(x => x.message).join(','),
      error: true
    };
  };

  return (
    <tr>
      <td>
        <TextField
          type="number"
          defaultValue=""
          value={fixedAmount ?? ''}
          name={`[${index}].fixedAmount`}
          placeholder="-"
          {...getErrorProps(errors, `[${index}].fixedAmount`)}
          onChange={e =>
            props.onChange(props.item, 'fixedAmount', e.target.value)
          }
        />
      </td>
      <td>
        <TextField
          type="number"
          defaultValue=""
          value={rateAmount ?? ''}
          name={`[${index}].rateAmount`}
          placeholder="-"
          {...getErrorProps(errors, `[${index}].rateAmount`)}
          onChange={e =>
            props.onChange(props.item, 'rateAmount', e.target.value)
          }
        />
      </td>
      <td>
        <TextField
          type="number"
          defaultValue=""
          value={minAmount ?? ''}
          name={`[${index}].minAmount`}
          placeholder="-"
          {...getErrorProps(errors, `[${index}].minAmount`)}
          onChange={e =>
            props.onChange(props.item, 'minAmount', e.target.value)
          }
        />
      </td>
      <td>
        <TextField
          type="number"
          defaultValue=""
          value={maxAmount ?? ''}
          name={`[${index}].maxAmount`}
          placeholder="-"
          {...getErrorProps(errors, `[${index}].maxAmount`)}
          onChange={e =>
            props.onChange(props.item, 'maxAmount', e.target.value)
          }
        />
      </td>
      <td>
        <Select
          MenuProps={{
            style: { zIndex: 999999999 }
          }}
          style={{
            width: '100%'
          }}
          defaultValue={currency ?? ''}
          value={currency ?? ''}
          name={`[${index}].currency`}
          {...getErrorProps(errors, `[${index}].currency`)}
          onChange={e => props.onChange(props.item, 'currency', e.target.value)}
        >
          <MenuItem value="">-please select-</MenuItem>
          <MenuItem value="GBP">GBP</MenuItem>
          <MenuItem value="EUR">EUR</MenuItem>
          <MenuItem value="USD">USD</MenuItem>
        </Select>
      </td>
      <td>
        {props.index === 0 ? (
          <AppBadge text="primary" />
        ) : (
          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={() => props.removeItem(props.index)}
          >
            Remove
          </button>
        )}
      </td>
    </tr>
  );
};

export default FeeAmounts;
