import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  FormSubmitWrapper,
  FormHandler,
  InputField
} from '../../../../../../@launchpad/index';
import LoginModel from '../models/LoginModel';
import { loginTriggerAction } from '../../../../../../@launchpad/modules/auth/AuthActions';
import { REQUIRE_OTP } from '../../../../../../@launchpad/logic/api/ActionCodes';

class LoginPage extends Component<any, any> {
  formHandler: FormHandler;

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: Readonly<{}>) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new LoginModel({
        username: '',
        password: '',
        rememberMe: true
      }),
      {
        redirect: false
      }
    );
  }

  componentDidMount() {
    this.setState({
      record: {
        ...this.state.record,
        username: '',
        password: '',
        rememberMe: true
      }
    });
  }

  onSubmitSuccess(data: {
    success: any;
    code: any;
    data: { accessToken: any; userRoles: any };
  }) {
    if (data.success && data.data.accessToken) {
      // this.props.userLoggedIn(data.data.accessToken, data.data.account);

      if (data.data.userRoles.includes('admin')) {
        this.props.userLoggedIn(data.data.accessToken, {
          profile: { id: 1, email: 'test@email.com' }
        });
      }

      setTimeout(() => {
        this.setState({
          redirect: true
        });
      }, 3000);
    }

    if (data.code === REQUIRE_OTP) {
      this.props.history.push('/login/otp', { otp: data.data });
    }
  }

  _getModalMessage(data: { success: any; message: any; data: any }) {
    if (!data.success) {
      return data.message;
    }
    if (!data.data.userRoles.includes('admin')) {
      return 'Not admin user';
    }

    if (data.success && !data.message) {
      return 'Authentication successful!';
    }

    return null;
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    const { formHandler } = this;
    return (
      <div className="public-form-holder">
        <h4>SIGN IN</h4>
        <div className="form-wrapper">
          <FormSubmitWrapper
            formHandler={formHandler}
            buttonClass="gold-button"
            to="/"
            buttonText="SIGN IN"
            noCancelButton
            getModalMessage={(data: {
              success: any;
              message: any;
              data: any;
            }) => this._getModalMessage(data)}
          >
            <div className="login-input-wrapper">
              <InputField
                className="input-group"
                materialProps={{
                  fullWidth: true
                }}
                autoComplete={this.state.record.username}
                label="E-mail"
                name="username"
                // placeholder={'E-mail'}
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.record.username}
                handler={formHandler}
              />
            </div>
            <div className="login-input-wrapper">
              <InputField
                className="input-group"
                materialProps={{
                  fullWidth: true
                }}
                autoComplete={this.state.record.password}
                label="Password"
                type="password"
                name="password"
                // placeholder={'Password'}
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.record.password}
                handler={formHandler}
              />
            </div>
          </FormSubmitWrapper>
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}
          >
            <Link
              to="/forgot-password"
              style={{
                color: 'white',
                textDecoration: 'none',
                fontWeight: 'lighter',
                fontSize: 15
              }}
            >
              {' '}
              Forgot password?{' '}
            </Link>
          </div>

          {/* <AppCheckboxInput
            name='remember'
            id='rememberField'
            htmlFor='rememberField'
            labelText='Remember me next time'
            value={this.state.remember}
            handler={this.formHandler}
          /> */}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (
  dispatch: (arg0: {
    type: string;
    payload: { token: any }; // Initial state
  }) => void
) => {
  return {
    userLoggedIn: (token: any, account: any) => {
      return dispatch(loginTriggerAction(token, account));
    }
  };
};

export default connect(null, mapDispatchToProps)(LoginPage);
