import React, { useState, useEffect } from 'react';
import Api from '@launchpad/logic/api/Api';
import { LoadingOverlay } from '@launchpad';

function NewUsersWidget(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<{
    lastMonth: number | string;
    lastWeek: number | string;
    lastYear: number | string;
    total: number | string;
  }>({
    lastMonth: 0,
    lastWeek: 0,
    lastYear: 0,
    total: 0
  });

  useEffect(() => {
    _loadStats();
  }, []);

  const _loadStats = async () => {
    const result: any = await Api.call('admin/users/stats', 'GET');

    if (result.success) {
      setLoading(false);
      setData(result.data);
    }
  };

  return (
    <div className="widget">
      <div className="dark-blur" />
      <div className="widget-header">
        <h3 className="widget-title">
          <i className="fa fa-users" aria-hidden="true" /> New Users
        </h3>
      </div>
      <div className="widget-inner">
        {loading ? (
          <LoadingOverlay />
        ) : (
          <div className="widget-new-customers">
            <div>
              <i className="fa fa-users" aria-hidden="true" />
              <p className="customers-number">
                {data.total} <span>total users</span>
              </p>
            </div>
            <ul>
              <li>Last week</li>
              <li> {data.lastWeek}</li>
            </ul>
            <ul>
              <li>Last month</li>
              <li> {data.lastMonth}</li>
            </ul>
            <ul>
              <li>Last year</li>
              <li> {data.lastYear}</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default NewUsersWidget;
