/* eslint-disable no-nested-ternary */
import React from 'react';

const useOfAccountOptions = [
  { key: 'occasionalSpending', value: 'occasional spending' },
  { key: 'dayToDaySpending', value: 'day-to-day spending' },
  { key: 'payingBills', value: 'paying bills' },
  { key: 'saving', value: 'saving' },
  { key: 'investments', value: 'investments' },
  { key: 'foreignCurrency', value: 'foreign currency' },
  { key: 'receivingPayments', value: 'receiving payments' },
  { key: 'payingSalaries', value: 'paying salaries' },
  { key: 'payingSuppliers', value: 'paying suppliers' },
  { key: 'employeeDebitCards', value: 'employee debit cards' }
];

const UserDetailsBasicAfter = ({ user }) => {
  const { additionalData } = user;

  if (!additionalData.compliance && !additionalData.website) {
    return null;
  }
  const parsedData = !additionalData.website
    ? JSON.parse(additionalData.compliance)
    : null;

  return (
    <div style={{ marginBottom: 20 }} className="col-lg-6">
      <h4>Additional Info</h4>
      <hr />
      <dl>
        {additionalData.website ? (
          <>
            <dt>Website:</dt>
            <dd>{additionalData.website || '-'}</dd>
            <dt>Company Description:</dt>
            <dd>{additionalData.companyDescription || '-'}</dd>
            <dt>Annual Turnover:</dt>
            <dd>{additionalData.annualTurnover || '-'}</dd>
            <dt>Years in Business:</dt>
            <dd>{additionalData.yearsInBusiness || '-'}</dd>
            <dt>Use of Account:</dt>
            <dd>{additionalData.useOfAccount || '-'}</dd>
            <dt>Expected Monthly Volume:</dt>
            <dd>{additionalData.expectedMonthlyVolume || '-'}</dd>
            <dt>Transfers In:</dt>
            <dd>{additionalData.transfersIn || '-'}</dd>
            <dt>Transfers Out:</dt>
            <dd>{additionalData.transfersOut || '-'}</dd>
          </>
        ) : parsedData.howDoYouExpectToUse ? (
          <>
            <dt>How do you expect to use:</dt>
            <dd>
              {parsedData.howDoYouExpectToUse
                .map(key => useOfAccountOptions.find(o => o.key === key)?.value)
                .join(', ') || '-'}
            </dd>
            <dt>How much you expect to use:</dt>
            <dd>
              {parsedData.howMuchYouExpectToUse || '-'}
              {parsedData.howMuchYouExpectToUseCurrency || ''}
            </dd>
            {parsedData.employmentStatus && (
              <>
                <dt>Employment Status:</dt>
                <dd>{parsedData.employmentStatus || '-'}</dd>
              </>
            )}
          </>
        ) : null}
      </dl>
    </div>
  );
};

export default UserDetailsBasicAfter;
