/* eslint-disable react/require-default-props */
import React from 'react';

interface IProps {
  size?: number;
  thickness?: number;
  color?: string;
  style?: React.CSSProperties;
}

const AppCircularProgress: React.FC<IProps> = ({
  size = 40,
  thickness = 3.6,
  color,
  style
}) => {
  // Try to adjust behavior to work similar to CircularProgress from mui
  const calculatedThickness = size !== 40 ? Math.max(size / 10, 2) : thickness;
  const circleStyle = {
    width: size,
    height: size,
    border: `${calculatedThickness}px solid rgba(0, 0, 0, 0.1)`,
    borderTop: `${calculatedThickness}px solid ${color ||
      style?.color ||
      '#007bff'}`,
    borderRadius: '50%',
    animation: 'spin 1s linear infinite'
  };

  const keyframesStyle = `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `;

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div style={circleStyle} />
      <style>{keyframesStyle}</style>
    </div>
  );
};

export default AppCircularProgress;
