import React from 'react';

export const variants = {
  cyberstar: {
    logo: require('../../assets/img/logo-with-desc.png'),
    cardFront: require('../../assets/img/card-front.png'),
    loginText: () => (
      <h1>
        Welcome to Cyberstar
        <span style={{ display: 'block', textAlign: 'center' }}>
          Administration
        </span>
      </h1>
    )
  }
};

const currentVariant = process.env.REACT_APP_ADMIN_VARIANT || 'cyberstar';
let exportableVariant = variants[currentVariant];
if (!variants[currentVariant]) {
  exportableVariant = variants.cyberstar;
}
export const CurrentVariant = exportableVariant;
