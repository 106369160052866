export enum KYBStatus {
  // kycStatus === unverified
  PENDING_ACCOUNT_TYPE = 'PENDING_ACCOUNT_TYPE',
  PENDING_COMPANY_DETAILS = 'PENDING_COMPANY_DETAILS',
  PENDING_COMPANY_DOCUMENTS = 'PENDING_COMPANY_DOCUMENTS',
  PENDING_USER_DETAILS = 'PENDING_USER_DETAILS',
  PENDING_USER_DOCUMENTS = 'PENDING_USER_DOCUMENTS',
  UNRECOGNIZED = 'UNRECOGNIZED',
  // kycStatus === verified
  VERIFIED = 'VERIFIED',
  // kycStatus === verification_in_progress || referred
  VERIFICATION_IN_PROGRESS = 'VERIFICATION_IN_PROGRESS',
  REFERRED = 'REFERRED',
  // kycStatus === rejected
  REJECTED = 'REJECTED'
}

export enum KYCStatus {
  // User should be asked to upload documents (unverified)
  PENDING_DATA_RESUBMISSION = 'PENDING_DATA_RESUBMISSION',
  UNVERIFIED = 'UNVERIFIED',
  UNRECOGNIZED = 'UNRECOGNIZED',
  PENDING_FRONTEND_VERIFICATION = 'PENDING_FRONTEND_VERIFICATION',
  PARTIALLY_COMPLETED = 'PARTIALLY_COMPLETED',

  // User uploaded documents and waiting for verification
  VERIFICATION_IN_PROGRESS = 'VERIFICATION_IN_PROGRESS',
  REFERRED = 'REFERRED',

  // User's documents are checked: either approved or rejected
  VERIFIED = 'VERIFIED',
  REJECTED = 'REJECTED'
}
