import { AppConfig } from '@launchpad/types';

const config: AppConfig = {
  env: process.env.REACT_APP_ENVIRONMENT || 'dev',
  url: process.env.REACT_APP_URL || 'http://localhost',
  authStrategy: 'refresh-token',
  ignorePermissions: true,
  policiesEnabled: false,
  pluginName: 'cyber-star',
  vendorReportVersion: 2,
  lpliteUserDetailsTabs: [
    'basic',
    'kyc',
    'devices',
    'accounts',
    'transactions'
  ],
  enableUboDelete: true,
  organisationReportsType: ['KYB', 'KYB_REPORT'],
  organisationUboReportsType: ['KYC', 'PEP', 'SIS'],
  allowCreateManualCompany: true,
  showAMLcheckReport: true,
  allowEditUbo: true,
  findCompanyDisabled: true,
  enableIdenfyKybSync: true,
  enableCustomerDocumentUpload: true,
  allowUserDetailsEdit: true
};

export default config;
