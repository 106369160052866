import { AppRoute } from '@launchpad';
import BaseModule from '@launchpad/logic/module/BaseModule';
import React from 'react';
import AccountsNavigation from './AccountsNavigation';

export const NAME = 'accounts';

export default class AccountsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'accounts.*';
  }

  getNavigation() {
    return [
      {
        label: 'Accounts',
        icon: 'address-card',
        path: '/accounts',
        permission: 'accounts.*'
        // children: [
        //   {
        //     label: 'Accounts',
        //     icon: 'address-card',
        //     path: '/accounts',
        //     permission: 'accounts.*'
        //   },
        //   {
        //     label: 'Bulk Load',
        //     icon: 'plus',
        //     path: '/accounts/bulk-load',
        //     permission: 'accounts.*'
        //   }
        // ]
      }
    ];
  }

  getRoutes() {
    return [<AppRoute path="/accounts" component={AccountsNavigation} />];
  }
}
