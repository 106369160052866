import React from 'react';
import { AppRoute } from '@launchpad';
import BaseModule from '@launchpad/logic/module/BaseModule';

export const NAME = 'dashboard';

export default class ReportsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'reports.*';
  }

  getNavigation() {
    return [
      {
        label: 'Reports',
        icon: 'file',
        path: '/reports',
        permission: 'reports.*'
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}reports`}
        load={() => import('./ReportsNavigation')}
      />
    ];
  }
}
