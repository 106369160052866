import BaseModel from '../../../logic/model/BaseModel';

export default class FeeTypeModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/fees/types';
  }

  getDetailsUrl(id) {
    return `admin/fees/types/${id}`;
  }
}
