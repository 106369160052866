import BaseModel from '@launchpad/logic/model/BaseModel';

export default class AccountTransactionsModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  getListUrl() {
    // return 'admin/transactions?page=1&limit=20&userId={some.user.id}';
    return 'admin/transactions';
  }

  // getDetailsUrl() {
  //   return 'transaction/details';
  // }

  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: true,
        type: 'field'
      },
      {
        name: 'vendorReference',
        label: 'Vendor reference',
        sortable: false,
        type: 'field'
      },
      {
        name: 'note',
        label: 'Note',
        sortable: false,
        type: 'field'
      },
      {
        name: 'paymentDevice.pan',
        label: 'PAN',
        sortable: false,
        type: 'field'
      },
      {
        name: 'amount',
        label: 'Amount',
        sortable: false,
        type: 'field'
      },
      {
        name: 'postDate',
        label: 'Transaction Date',
        sortable: false,
        type: 'field'
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions'
      }
    ];
  }
}
