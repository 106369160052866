/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { AppBadge, LoadingOverlay, OverrideService } from '@launchpad';
import UsersModel from '../../models/UsersModel';
import ReportCollapsibleItem from '../../../components/ReportCollapsibleItem';
import UploadDocumentModal from '@launchpad/modules/lplite/organisations/modals/UploadDocumentModal';

class KycInfo extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      redirect: false,
      isLoading: false,
      KYCDocumentsUrls: [],
      KYCCheckInformation: null,
      PEPCheckInformation: null,
      SISCheckInformation: null,
      kycTestsTableCollapsed: false
    };
  }

  componentDidMount() {
    this._pullKYCDocuments();
    this._pullKYCCheckInformation();
  }

  onChangeUserStatusKyc(id, status) {
    this.setState({ isLoading: true });
    new UsersModel()
      .changeUserStatusKYC(id, status)
      .then(() => {
        this.props.onRefresh();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  toggleCollapse = name => {
    this.setState(prevState => ({ [name]: !prevState[name] }));
  };

  async _pullKYCDocuments() {
    const { id } = this.props.match.params;

    await new UsersModel().getKycDocument(id).then(result => {
      if (result && result.publicUrls) {
        this.setState({
          KYCDocumentsUrls: result.publicUrls
        });
      }
    });
  }

  async _pullKYCCheckInformation() {
    const { id } = this.props.match.params;

    await new UsersModel().getKYCCheckInformation(id).then(result => {
      if (result && result.length > 0) {
        this.setState({
          KYCCheckInformation: result[0],
          KYBCheckInformation: result[1],
          PEPCheckInformation: result[2],
          SISCheckInformation: result[3],
          KYBReportCheckInformation: result[4]
        });
      }
    });
  }

  renderResult(status) {
    switch (status.toLowerCase()) {
      case 'passed':
        return <AppBadge text={status} icon="check" />;
      case 'fail':
      case 'failed':
      case 'decline':
        return <AppBadge text={status} icon="times" type="danger" />;
      case 'refer':
        return <AppBadge text={status} icon="clock-o" type="info" />;
      default:
        return <AppBadge text={status} icon="clock-o" type="info" />;
    }
  }

  render() {
    const { user } = this.props;

    if (this.state.redirect) {
      return <Redirect to={`/user-details/${user.id}`} />;
    }
    const kycStatus = user.kycStatus || '';
    const newKycStatus = kycStatus.replace(/#|_/g, ' ');
    const userKycStatus = user.kycStatus ? user.kycStatus.toUpperCase() : '';

    const kybFullReportData =
      this.state.KYBReportCheckInformation?.response?.results
        ?.w2DataEkybUk009Result ||
      this.state.KYBReportCheckInformation?.response?.results
        ?.w2DataEkyb009Result;

    const kybCheckData =
      this.state.KYBCheckInformation?.response?.results
        ?.w2DataEkybUk009Result ||
      this.state.KYBCheckInformation?.response?.results?.w2DataEkyb009Result;

    return (
      <div className="main" key={user}>
        <div className="dark-blur" />
        <div className="customers-info">
          <div className="customer-details">
            <div>
              <h2 className="customer-name">
                <span className="company">
                  {user.firstName} {user.lastName}
                </span>
              </h2>
            </div>
            {/* <div>
              <div className="bottom" style={{ alignItems: 'center' }}>
                <Link
                  to={`/users/all-users/edit-user/${user.id}`}
                  className="button-info"
                  style={{ marginRight: 20 }}
                >
                  Edit
                </Link>
              </div>
            </div> */}
          </div>

          <div className="customers-main">
            <div className="row">
              <div className="col">
                {!user || this.state.isLoading ? (
                  <LoadingOverlay />
                ) : (
                  <div className="padded">
                    <div className="row text-white">
                      <div style={{ marginBottom: 20 }} className="col-lg-6">
                        <h4>KYC</h4>
                        <hr />
                        <dl>
                          <dt>Status:</dt>
                          <dd>{newKycStatus.toUpperCase()}</dd>
                        </dl>
                        <dl>
                          <dt>Change KYC status:</dt>
                          <dd>
                            <select
                              className="option-wrapper"
                              onChange={event =>
                                this.onChangeUserStatusKyc(
                                  user.id,
                                  event.target.value
                                )
                              }
                              value={
                                userKycStatus !== 'UNVERIFIED'
                                  ? userKycStatus
                                  : ''
                              }
                            >
                              <option disabled value="">
                                -- select an option --
                              </option>
                              <option value="PENDING_DATA_RESUBMISSION">
                                Request re-entry of personal details
                              </option>
                              <option value="PENDING_FRONTEND_VERIFICATION">
                                Pending documents
                              </option>
                              <option value="VERIFICATION_IN_PROGRESS">
                                Verification in progress
                              </option>
                              <option value="VERIFIED">Verified</option>
                              <option value="REJECTED">Rejected</option>
                            </select>
                          </dd>
                        </dl>

                        <ReportCollapsibleItem
                          title="KYC Check Information"
                          arrayOfItems={[
                            {
                              label: 'Result',
                              value:
                                this.state.KYCCheckInformation?.response
                                  ?.verification?.result ||
                                this.state.KYCCheckInformation?.response
                                  ?.results
                            },
                            {
                              label: 'Classification result',
                              value: this.state.KYCCheckInformation?.response
                                ?.classification?.classificationResult
                            },
                            {
                              label: 'Type',
                              value: this.state.KYCCheckInformation?.response
                                ?.classification?.classificationDetails?.type
                            },
                            {
                              label: 'Version',
                              value: this.state.KYCCheckInformation?.response
                                ?.classification?.classificationDetails?.version
                            },
                            {
                              label: 'Issuer code',
                              value: this.state.KYCCheckInformation?.response
                                ?.classification?.classificationDetails
                                ?.issuerCode
                            },
                            {
                              label: 'Issuer name',
                              value: this.state.KYCCheckInformation?.response
                                ?.classification?.classificationDetails
                                ?.issuerName
                            },
                            {
                              label: 'Classification name',
                              value: this.state.KYCCheckInformation?.response
                                ?.classification?.classificationDetails
                                ?.classificationName
                            }
                          ]}
                        />

                        <ReportCollapsibleItem
                          title="Meta data"
                          arrayOfItems={[
                            {
                              label: 'Name',
                              value: this.state.KYCCheckInformation?.response
                                ?.verification?.metaData?.name
                            },
                            {
                              label: 'Date of birth',
                              value: this.state.KYCCheckInformation?.response
                                ?.verification?.metaData?.dateOfBirth
                            },
                            {
                              label: 'Document number',
                              value: this.state.KYCCheckInformation?.response
                                ?.verification?.metaData?.documentNumber
                            },
                            {
                              label: 'Document expiry date',
                              value: this.state.KYCCheckInformation?.response
                                ?.verification?.metaData?.documentExpiryDate
                            }
                          ]}
                        />
                        <dl>
                          <div
                            style={{
                              cursor: 'pointer',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: 20
                            }}
                            onClick={() =>
                              this.toggleCollapse('kycTestsTableCollapsed')
                            }
                          >
                            <h4>Tests:</h4>
                            <i
                              className={`fa fa-chevron-${
                                this.state.kycTestsTableCollapsed
                                  ? 'up'
                                  : 'down'
                              }`}
                            />
                          </div>
                          <hr />
                          {this.state.kycTestsTableCollapsed && (
                            <table className="table">
                              <tbody>
                                <tr className="tr-dark-bg">
                                  <td>Test</td>
                                  <td>Description</td>
                                  <td>Result</td>
                                </tr>
                                {this.state.KYCCheckInformation?.response?.verification?.alerts.map(
                                  (item, index) => {
                                    return (
                                      <tr key={`test${index}`}>
                                        <td>{item.name}</td>
                                        <td>{item.description}</td>
                                        <td>
                                          {this.renderResult(item.result)}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          )}
                        </dl>
                        <ReportCollapsibleItem
                          title="PEP Result"
                          arrayOfItems={[
                            {
                              label: 'Service interpret result',
                              value: this.state.PEPCheckInformation?.response
                                ?.transaction?.serviceTransactions?.[0]
                                ?.serviceInterpretResult
                            },
                            {
                              label: 'Validation result',
                              value: this.state.PEPCheckInformation?.response
                                ?.transaction?.serviceTransactions?.[0]
                                ?.validationResult
                            },
                            {
                              table: this.state.PEPCheckInformation?.response
                                ?.results?.pepDeskCheckResult?.matchResults
                            }
                          ]}
                        />
                        <ReportCollapsibleItem
                          title="SIS Result"
                          arrayOfItems={[
                            {
                              label: 'Service interpret result',
                              value: this.state.SISCheckInformation?.response
                                ?.transaction?.serviceTransactions?.[0]
                                ?.serviceInterpretResult
                            },
                            {
                              label: 'Validation result',
                              value: this.state.SISCheckInformation?.response
                                ?.transaction?.serviceTransactions?.[0]
                                ?.validationResult
                            },
                            {
                              table: this.state.SISCheckInformation?.response
                                ?.results?.sisPlusCheckResult?.matchResults
                            }
                          ]}
                        />

                        <ReportCollapsibleItem
                          title="KYB Result"
                          arrayOfItems={[
                            {
                              label: 'Name',
                              value:
                                kybCheckData?.companySearchResults?.[0]?.name
                            },
                            {
                              label: 'Registration Number',
                              value:
                                kybCheckData?.companySearchResults?.[0]
                                  ?.registrationNumber
                            },
                            {
                              label: 'Company Type',
                              value:
                                kybCheckData?.companySearchResults?.[0]
                                  ?.companyType
                            },
                            {
                              label: 'Country',
                              value:
                                kybCheckData?.companySearchResults?.[0]?.country
                            },
                            {
                              label: 'Address',
                              value:
                                kybCheckData?.companySearchResults?.[0]?.address
                                  ?.address
                            },
                            {
                              label: 'Postcode',
                              value:
                                kybCheckData?.companySearchResults?.[0]?.address
                                  ?.postcode
                            },
                            {
                              label: 'Telephone Number',
                              value:
                                kybCheckData?.companySearchResults?.[0]?.address
                                  ?.telephoneNumber
                            },
                            {
                              label: 'Interpret Result',
                              value: this.state.KYBCheckInformation?.response
                                ?.transaction?.interpretResult
                            }
                          ]}
                        />

                        <ReportCollapsibleItem
                          title="KYB Full Report Result"
                          arrayOfItems={[
                            {
                              label: 'Business Name',
                              value:
                                kybFullReportData?.companyReports?.[0]?.summary
                                  ?.businessName
                            },
                            {
                              label: 'Number',
                              value:
                                kybFullReportData?.companyReports?.[0]?.summary
                                  ?.number
                            },
                            {
                              label: 'Country',
                              value:
                                kybFullReportData?.companyReports?.[0]?.summary
                                  ?.country
                            },
                            {
                              label: 'Company Registration Number',
                              value:
                                kybFullReportData?.companyReports?.[0]?.summary
                                  ?.companyRegistrationNumber
                            },
                            {
                              label: 'Company Status',
                              value:
                                kybFullReportData?.companyReports?.[0]?.summary
                                  ?.companyStatus
                            },
                            {
                              label: 'Last Turnover Figure',
                              value:
                                kybFullReportData?.companyReports?.[0]?.summary
                                  ?.lastTurnoverFigure
                            },
                            {
                              label: 'Latest Shareholder Equity Figure',
                              value:
                                kybFullReportData?.companyReports?.[0]?.summary
                                  ?.latestShareholderEquityFigure
                            },
                            {
                              label: 'Vat Registration Number',
                              value:
                                kybFullReportData?.companyReports?.[0]?.summary
                                  ?.vatRegistrationNumber
                            },
                            {
                              label: 'Vat Registration Date',
                              value:
                                kybFullReportData?.companyReports?.[0]?.summary
                                  ?.vatRegistrationDate
                            },
                            {
                              label: 'Date Changed From Previous Name',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.previousNames?.[0]
                                  ?.dateChangedFromPreviousName
                            },
                            {
                              label: 'Revenue',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]
                                  ?.revenue
                            },
                            {
                              label: 'Operating Costs',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]
                                  ?.operatingCosts
                            },
                            {
                              label: 'Operating Profit',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]
                                  ?.operatingProfit
                            },
                            {
                              label: 'Wages And Salaries',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]
                                  ?.wagesAndSalaries
                            },
                            {
                              label: 'Pension Costs',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]
                                  ?.pensionCosts
                            },
                            {
                              label: 'Revenue',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]
                                  ?.revenue
                            },
                            {
                              label: 'Depreciation',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]
                                  ?.depreciation
                            },
                            {
                              label: 'Amortisation',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]
                                  ?.amortisation
                            },
                            {
                              label: 'Financial Income',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]
                                  ?.financialIncome
                            },
                            {
                              label: 'Financial Expenses',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]
                                  ?.financialExpenses
                            },
                            {
                              label: 'Extraordinary Income',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]
                                  ?.extraordinaryIncome
                            },
                            {
                              label: 'Extraordinary Costs',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]
                                  ?.extraordinaryCosts
                            },
                            {
                              label: 'Profit Before Tax',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]
                                  ?.profitBeforeTax
                            },
                            {
                              label: 'Tax',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]?.tax
                            },
                            {
                              label: 'Profit After Tax',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]
                                  ?.profitAfterTax
                            },
                            {
                              label: 'Dividends',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]
                                  ?.dividends
                            },
                            {
                              label: 'Minority Interests',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]
                                  ?.minorityInterests
                            },
                            {
                              label: 'Other Appropriations',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]
                                  ?.otherAppropriations
                            },
                            {
                              label: 'Retained Profit',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]
                                  ?.retainedProfit
                            },
                            {
                              label: 'Financial Year',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]
                                  ?.financialYear
                            },
                            {
                              label: 'Number of Weeks',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]
                                  ?.numberOfWeeks
                            },
                            {
                              label: 'Currency',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.financeSheets?.[0]?.profitAndLoss?.[0]
                                  ?.currency
                            },
                            {
                              label: 'Main Address',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.mainAddress
                            },
                            {
                              label: 'Previous Address',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.previousAddresses?.[0]
                            },
                            {
                              label: 'Other Address',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.otherAddresses?.[0]
                            },
                            {
                              label: 'Email Address',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.emailAddress
                            },
                            {
                              label: 'Issued Shared Capital',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.shareCapitalStructure?.issuedShareCapital
                            },
                            {
                              label: 'Nominal Shared Capital',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.shareCapitalStructure?.nominalShareCapital
                            },
                            {
                              label: '',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.employeeInformation?.[0]
                            },
                            {
                              label: 'Banker Name',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.bankerInformation?.[0]?.bankerName
                            },
                            {
                              label: 'Banker Code',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.bankerInformation?.[0]?.bankerCode
                            },
                            {
                              label: 'Banker Address',
                              value:
                                kybFullReportData?.companyReports?.[0]
                                  ?.bankerInformation?.[0]?.bankerAddress
                            },
                            {
                              label: 'Validation Result',
                              value: this.state.KYBReportCheckInformation
                                ?.response?.transaction
                                ?.serviceTransactions?.[0]?.validationResult
                            },
                            {
                              label: 'Shareholders',
                              table:
                                kybFullReportData?.companyReports?.[0]
                                  ?.shareCapitalStructure?.shareholders
                            },
                            {
                              table:
                                kybFullReportData?.companyReports?.[0]
                                  ?.previousNames
                            },
                            {
                              label: 'Current Directors',
                              table:
                                kybFullReportData?.companyReports?.[0]
                                  ?.currentDirectors
                            }
                          ]}
                        />
                      </div>
                      <div style={{ marginBottom: 20 }} className="col-lg-6">
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <h4 style={{ margin: 0 }}>
                            KYC Documents Submission
                          </h4>
                          {OverrideService.getConfig()
                            .enableCustomerDocumentUpload && (
                            <>
                              <div
                                onClick={() =>
                                  this.setState({
                                    showUploadDocumentModal: true
                                  })
                                }
                                className="button"
                              >
                                <i className="fa fa-fw fa-plus" />
                                Upload new
                              </div>
                              <UploadDocumentModal
                                showModal={this.state.showUploadDocumentModal}
                                documentTypes={[
                                  { value: 'PASSPORT', label: 'Passport' },
                                  {
                                    value: 'DRIVINGLICENCE',
                                    label: 'Driving Licence'
                                  },
                                  { value: 'SELFIE', label: 'Selfie' },
                                  { value: 'POA', label: 'Proof of address' },
                                  { value: 'OTHER', label: 'Other' }
                                ]}
                                // uboId={userId}
                                // organisationId={organisationId}
                                userId={user.id}
                                onClose={() => {
                                  this.setState({
                                    showUploadDocumentModal: false
                                  });
                                  this._pullKYCDocuments();
                                }}
                              />
                            </>
                          )}
                        </div>
                        <hr />
                        <div className="d-flex align-items-center flex-column">
                          {this.state.KYCDocumentsUrls.length ? (
                            this.state.KYCDocumentsUrls.map((item, index) => {
                              return (
                                <>
                                  <span>Document type: {item.type}</span>
                                  <img
                                    key={`document${index}`}
                                    src={item.publicUrl}
                                    style={{
                                      width: '100%',
                                      height: 'auto',
                                      maxWidth: 300,
                                      marginTop: 10,
                                      marginBottom: 40
                                    }}
                                    alt="document"
                                  />
                                </>
                              );
                            })
                          ) : (
                            <h4 className="m-3">No KYC documents</h4>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(KycInfo);
