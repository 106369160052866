/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { AppPage, AppFetcher, AppWidget } from '@launchpad/index';
import UserTierModel from '../../models/UserTierModel';
import UserTierForm from '../../components/UserTierForm';

const UserTierEdit = props => (
  <AppPage title="Edit user tier" icon="pencil">
    <div className="container">
      <AppFetcher
        model={UserTierModel}
        id={props.match.params.id}
        isIdInUrl
        renderContent={record => (
          <AppWidget title={record.name} icon={null} padded>
            <UserTierForm record={record} />
          </AppWidget>
        )}
      />
    </div>
  </AppPage>
);

export default UserTierEdit;
