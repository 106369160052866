import React from 'react';
import BaseModule from '@launchpad/logic/module/BaseModule';
import { AppRoute } from '@launchpad';
import Dashboard from './Dashboard';

export const NAME = 'dashboard';

export default class DashboardModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'dashboard.*';
  }

  getNavigation() {
    return [
      {
        label: 'Dashboard',
        icon: 'tachometer',
        path: '/',
        permission: 'dashboard.*'
      }
    ];
  }

  getRoutes() {
    return [<AppRoute exact path="/" component={Dashboard} />];
  }
}
