export default class ValidateEmailService {
  static validateEmail(email: string) {
    const mailformat = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email.length === 0) {
      throw new Error('Please enter an email address');
    } else if (!email.match(mailformat)) {
      throw new Error('Please enter valid email address');
    }

    return true;
  }
}
