import { ModalDialog } from '@launchpad/components';
import AppSelect from '@launchpad/components/crud/AppSelect';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Api from '../../../../logic/api/Api';
import UserTierModel from '../models/UserTierModel';

const UserTierUserInfoElement = props => {
  const { user } = props;
  const { userTier } = user;

  const [showChangeModal, setShowChangeModal] = useState(false);
  const [newlySelectedTierId, setNewlySelectedTierId] = useState(null);
  const [userTiers, setUserTiers] = useState([]);
  const [modalHeight, setMenuHeight] = useState(300);

  useEffect(() => {
    new UserTierModel().fetchFlat({ limit: 200 }).then(result => {
      setUserTiers(result);
    });
  }, []);

  const saveTier = async () => {
    const result = await Api.call(`admin/users/${user.id}`, 'patch', {
      userTierId: newlySelectedTierId
    });
    if (result.success) {
      if (props.onSuccess) props.onSuccess();
    } else {
      if (props.onFailure) props.onFailure();
    }
  };

  return (
    <div>
      <h4>
        User Tier Info
        <div style={{ float: 'right' }}>
          {userTier ? (
            <Link to={`/user-tiers/${userTier?.id}`} className="button btn-sm">
              Details
            </Link>
          ) : null}
          <button
            type="button"
            className="button-info btn-sm"
            onClick={() => setShowChangeModal(true)}
          >
            Change
          </button>
        </div>
      </h4>
      <hr />
      {userTier ? (
        <dl>
          <dt>Tier id</dt>
          <dd>{userTier?.id || '-'}</dd>
          <dt>Name:</dt>
          <dd>{userTier?.name || '-'}</dd>
        </dl>
      ) : (
        <em> - tier not set - </em>
      )}
      <ModalDialog
        show={showChangeModal}
        onClose={() => setShowChangeModal(false)}
      >
        <div style={{ minHeight: modalHeight }}>
          <h3>Change user tier</h3>
          <hr />
          <div style={{ textAlign: 'center' }}>
            <p style={{ fontSize: 18 }}>
              Current user tier:{' '}
              <strong>{userTier?.name || ' - not set - '}</strong>
            </p>
            <br />
            <p>Please select a new user tier:</p>
            {userTiers && userTiers.length > 0 ? (
              <>
                <AppSelect
                  textFieldProps={{
                    label: 'User tier',
                    InputLabelProps: {
                      shrink: true
                    }
                  }}
                  options={userTiers}
                  name="userTierId"
                  value={newlySelectedTierId || userTier?.id}
                  onChange={value => {
                    setNewlySelectedTierId(value);
                  }}
                  placeholder="- select a tier -"
                  maxMenuHeight={modalHeight - 200}
                  onMenuOpen={() => setMenuHeight(450)}
                  onMenuClose={() => setMenuHeight(300)}
                />
                <button
                  onClick={() => setShowChangeModal(false)}
                  className="button"
                  type="button"
                >
                  Cancel
                </button>
                <button
                  onClick={() => saveTier()}
                  className="button-info"
                  type="button"
                >
                  Save
                </button>
              </>
            ) : null}
          </div>
        </div>
      </ModalDialog>
    </div>
  );
};

export default UserTierUserInfoElement;
