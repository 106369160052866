import React, { Component } from 'react';
import OverrideService from '@launchpad/logic/services/overrides/OverrideService';
import { FormSubmitWrapper, FormHandler, InputField } from '../../../index';
import AppSelect from '../../../components/crud/AppSelect';
import FeeTypeModel from '../models/FeeTypeModel';
import CreateUpdateFeeModel from '../models/CreateUpdateFeeModel';
import FeeAmounts from './FeeAmounts';
import FeeConditions from './FeeConditions';

export default class CreateUpdateFeeForm extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new CreateUpdateFeeModel({
        id: this.props.id,
        feeGroupId: this.props.feeGroupId,
        entityId: this.props.entityId,
        entity: this.props.entity,
        title: '',
        feeId: '',
        amount: '',
        currency: this.props.currency ?? 'GBP',
        collectionMethod: '',
        configuration: {
          toAccountId:
            OverrideService.getOverrides().feeCustomConfiguration || undefined,
          secondaryAmounts: []
        },
        ...(this.props.record ?? {})
      })
    );
  }

  onSubmitSuccess() {
    if (this.props.onSubmitSuccess) {
      this.props.onSubmitSuccess();
    }
  }

  getSubmitData(record) {
    return {
      ...record,
      rateAmount: parseFloat(record.rateAmount),
      fixedAmount: parseFloat(record.fixedAmount),
      minAmount: parseFloat(record.minAmount),
      maxAmount: parseFloat(record.maxAmount)
    };
  }

  _getModalMessage(data) {
    if (!data.success) {
      return data.message;
    }
    if (data.success) {
      return this.state.record.id
        ? 'Fee updated successfully!'
        : 'Fee created successfully!';
    }
    return null;
  }

  _applyAmounts(amounts) {
    // Handle the first amount
    const firstAmount = {
      ...amounts[0]
    };

    const otherAmounts = amounts.splice(1);

    this.setState(prevState => ({
      record: {
        ...prevState.record,
        ...firstAmount,
        configuration: {
          ...prevState.record.configuration,
          secondaryAmounts: otherAmounts
        }
      }
    }));
  }

  _applyConditions(conditions) {
    this.setState(prevState => ({
      record: {
        ...prevState.record,
        configuration: {
          ...prevState.record.configuration,
          conditions
        }
      }
    }));
  }

  render() {
    const { formHandler } = this;

    return (
      <div>
        <h3 className="text-center">
          {this.state.record.id
            ? `Edit fee: ${this.state.record.name}`
            : `Create a fee`}
        </h3>
        <div className="element-with-blur" style={{ paddingTop: '2px' }}>
          <div className="container">
            <FormSubmitWrapper
              formHandler={formHandler}
              to={this.props.redirectRoute}
              buttonText={this.props.buttonText}
              customCloseFunction={this.props.customCloseFunction}
              getModalMessage={data => this._getModalMessage(data)}
            >
              <div className="row">
                <div className="col-sm-6">
                  <InputField
                    materialProps={{
                      fullWidth: true
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    label="Fee Name*"
                    type="text"
                    name="name"
                    value={this.state.record.name}
                    handler={formHandler}
                  />

                  <AppSelect
                    getOptionValue={product => product.id}
                    wrapperStyle={{
                      marginBottom: 10,
                      marginTop: 10,
                      zIndex: 9999999
                    }}
                    textFieldProps={{
                      label: 'Fee Type*',
                      InputLabelProps: {
                        shrink: true
                      }
                    }}
                    params={{
                      limit: 100,
                      page: 1
                    }}
                    model={FeeTypeModel}
                    name="feeTypeId"
                    value={this.state.record.feeTypeId}
                    handler={formHandler}
                    placeholder="Select Fee Type"
                  />
                </div>
                <div className="col-sm-6">
                  <InputField
                    materialProps={{
                      fullWidth: true
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    label="Description"
                    type="text"
                    name="description"
                    value={this.state.record.description}
                    handler={formHandler}
                  />

                  <AppSelect
                    getOptionValue={product => product.id}
                    wrapperStyle={{
                      marginBottom: 10,
                      marginTop: 10,
                      zIndex: 99999
                    }}
                    textFieldProps={{
                      label: 'Collection Type*',
                      InputLabelProps: {
                        shrink: true
                      }
                    }}
                    options={[
                      {
                        id: 'MANUAL',
                        name: 'Manual'
                      },
                      {
                        id: 'AUTOMATIC',
                        name: 'Automatic'
                      }
                    ]}
                    params={{
                      limit: 100,
                      page: 1
                    }}
                    name="collectionMethod"
                    value={this.state.record.collectionMethod}
                    handler={formHandler}
                    placeholder="Select collection method"
                  />
                </div>
              </div>
              <FeeAmounts
                fee={this.state.record}
                onChange={amounts => this._applyAmounts(amounts)}
              />
              <FeeConditions
                fee={this.state.record}
                onChange={amounts => this._applyConditions(amounts)}
              />
            </FormSubmitWrapper>
          </div>
        </div>
      </div>
    );
  }
}

CreateUpdateFeeForm.defaultProps = {
  entityId: null,
  entity: null
};
