/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { KYBStatus } from '@launchpad/modules/auth/types/auth.types';
import UserTierUserInfoElement from '@launchpad/modules/users/user-tiers/components/UserTierUserInfoElement';
import { date } from '../../../../../util/DateHelper';
import { LPOverridable } from '../../../../../components';
import { LoadingOverlay, OverrideService } from '../../../../../index';
// import UserTierUserInfoElement from '../../../../../../@launchpad/modules/users/user-tiers/components/UserTierUserInfoElement';
import UsersModel from '../../models/UsersModel';

class CustomerBasicInfo extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      redirect: false
    };
  }

  onChangeUserStatusKyc(id, status) {
    new UsersModel()
      .changeUserKycKybStatusBasedOnKyb(id, status)
      .then(() => {
        this.props.onRefresh();
      })
      .catch(error => {
        console.log(error);
      });
    // .finally(() => this.setState({ isLoading: false }));
  }

  render() {
    const { user } = this.props;

    if (this.state.redirect) {
      return <Redirect to={`/user-details/${user.id}`} />;
    }

    if (!user.id) {
      return <LoadingOverlay />;
    }

    const userStatus =
      user.onboardingStep?.toUpperCase() ||
      user.kybStatus?.toUpperCase() ||
      KYBStatus.PENDING_ACCOUNT_TYPE;

    return (
      <div className="main" key={user}>
        <div className="dark-blur" />
        <div className="customers-info">
          <div className="customer-details">
            <div>
              <h2 className="customer-name">
                <span className="company">
                  {user.firstName} {user.lastName}
                </span>
              </h2>
            </div>
            {OverrideService.getConfig().allowUserDetailsEdit && (
              <div>
                <div className="bottom" style={{ alignItems: 'center' }}>
                  <Link
                    to={`/user-details/${user.id}/edit`}
                    className="button-info"
                    style={{ minWidth: 100 }}
                  >
                    Edit
                  </Link>
                </div>
              </div>
            )}
          </div>
          <div className="customers-main">
            <div className="row">
              <div className="col">
                {!user ? (
                  <LoadingOverlay />
                ) : (
                  <div className="padded">
                    <LPOverridable
                      name="user.details.basic.before"
                      user={user}
                    />
                    <div className="row text-white">
                      <div style={{ marginBottom: 20 }} className="col-lg-6">
                        <h4>Basic Info</h4>
                        <hr />
                        <dl>
                          <dt>First name:</dt>
                          <dd>{user.firstName || '-'}</dd>
                          <dt>Last name:</dt>
                          <dd>{user.lastName || '-'}</dd>
                          <dt>Username:</dt>
                          <dd>{user.username ? user.username : '-'}</dd>
                          <dt>E-mail address:</dt>
                          <dd>{user.email || '-'}</dd>
                          <dt>Customer id:</dt>
                          <dd>{user.id || '-'}</dd>
                          <dt>Birth date:</dt>
                          <dd>{date(user.birthDate, 'D / MMMM / Y') || '-'}</dd>
                          <dt>Onboarding:</dt>
                          <dd>
                            {date(user.registeredAt, 'D / MMMM / Y HH:mm') ||
                              '-'}
                          </dd>
                          {user.organisationId && (
                            <>
                              <dt>Company:</dt>
                              <dd>
                                <Link
                                  to={`/users/organisations/${user.organisationId}`}
                                  className="button-info"
                                >
                                  Info
                                </Link>
                              </dd>
                            </>
                          )}
                        </dl>
                      </div>
                      <div style={{ marginBottom: 20 }} className="col-lg-6">
                        <h4>Address Info</h4>
                        <hr />
                        <dl>
                          <dt>Address Line One:</dt>
                          <dd>{user.addressOne || '-'}</dd>
                          <dt>Address Line Two:</dt>
                          <dd>{user.addressTwo || '-'}</dd>
                          <dt>City:</dt>
                          <dd>{user.city || '-'}</dd>
                          <dt>Postcode:</dt>
                          <dd>{user.postCode || '-'}</dd>
                          <dt>Country code:</dt>
                          <dd>{user.countryCode || '-'}</dd>
                          <dt>Mobile:</dt>
                          <dd>{user.phoneNumber || '-'}</dd>
                        </dl>
                        <div style={{ marginTop: 20 }}>
                          <UserTierUserInfoElement
                            user={user}
                            onSuccess={() => window.location.reload()}
                          />
                        </div>
                        <div style={{ marginTop: 20 }}>
                          <h4>Onboarding Step</h4>
                          <hr />
                          <dl>
                            <dt>Change onboarding step:</dt>
                            <dd>
                              <select
                                className="option-wrapper"
                                onChange={event =>
                                  this.onChangeUserStatusKyc(
                                    user.id,
                                    event.target.value
                                  )
                                }
                                value={userStatus || ''}
                              >
                                <option disabled value="">
                                  -- select an option --
                                </option>
                                {user.organisationId && (
                                  <>
                                    <option
                                      value={KYBStatus.PENDING_COMPANY_DETAILS}
                                    >
                                      Pending company details
                                    </option>
                                    <option
                                      value={
                                        KYBStatus.PENDING_COMPANY_DOCUMENTS
                                      }
                                    >
                                      Pending company documents
                                    </option>
                                  </>
                                )}
                                <option value={KYBStatus.PENDING_USER_DETAILS}>
                                  Pending user details
                                </option>
                                <option
                                  value={KYBStatus.PENDING_USER_DOCUMENTS}
                                >
                                  Pending user documents
                                </option>
                                <option
                                  value={KYBStatus.VERIFICATION_IN_PROGRESS}
                                >
                                  Verification in progress
                                </option>
                                <option value={KYBStatus.REFERRED}>
                                  Referred
                                </option>
                                <option value={KYBStatus.REJECTED}>
                                  Rejected
                                </option>
                                <option value={KYBStatus.VERIFIED}>
                                  Verified
                                </option>
                              </select>
                            </dd>
                          </dl>
                        </div>
                      </div>

                      <div style={{ marginBottom: 20 }} className="col-lg-6">
                        <h4>User errors</h4>
                        <hr />
                        {user.userErrors && user.userErrors.length > 0 ? (
                          user.userErrors.map(e => (
                            <>
                              <span>{e.errorMessage || '-'}</span>
                              <br />
                              <br />
                            </>
                          ))
                        ) : (
                          <span>No errors</span>
                        )}
                      </div>
                      <LPOverridable
                        name="user.details.basic.after"
                        user={user}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CustomerBasicInfo);
