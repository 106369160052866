import BaseModel from '../../../logic/model/BaseModel';

export default class CreateUpdateFeeModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'POST';
  }

  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'admin/fees';
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      name: {
        presence: {
          allowEmpty: false,
          message: '^Please enter fee name'
        }
      },
      feeTypeId: {
        presence: {
          allowEmpty: false,
          message: '^Please select fee type'
        }
      }
    };
  }
}
