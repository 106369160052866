import React from 'react';
import UserForm from './user-form/UserForm';

const EditUser = props => {
  return (
    <div>
      <UserForm title="Edit User" id={props.match.params.id} />
    </div>
  );
};

export default EditUser;
