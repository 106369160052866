/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import 'react-datepicker/dist/react-datepicker.css';
import {
  FormSubmitWrapper,
  FormHandler,
  InputField,
  AppCheckboxInput
} from '@launchpad/index';
import AppSelect from '@launchpad/components/crud/AppSelect';
import UserTierModel from '../models/UserTierModel';

export default class UserTierForm extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {};

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new UserTierModel({
        id: undefined,
        name: '',
        description: '',
        isDefault: false,
        autoAssignmentStrategy: 'NONE',
        ...(props.record ? props.record : {})
      })
    );
  }

  getSubmitUrl(record) {
    if (record?.id) {
      return `admin/user-tiers/${record.id}`;
    }

    return `admin/user-tiers`;
  }

  getSubmitMethod(record) {
    if (record?.id) {
      return `PATCH`;
    }

    return `POST`;
  }

  render() {
    const { formHandler } = this;

    return (
      <FormSubmitWrapper formHandler={formHandler} to="/user-tiers">
        <InputField
          materialProps={{
            fullWidth: true
          }}
          InputLabelProps={{
            shrink: true
          }}
          customInput={TextField}
          label="Name"
          type="text"
          name="name"
          value={this.state.record.name}
          handler={formHandler}
        />

        <InputField
          materialProps={{
            fullWidth: true
          }}
          InputLabelProps={{
            shrink: true
          }}
          label="Description"
          type="text"
          name="description"
          value={this.state.record.description}
          handler={formHandler}
        />

        <AppSelect
          textFieldProps={{
            label: 'Auto-assignment strategy',
            InputLabelProps: {
              shrink: true
            }
          }}
          options={[
            {
              id: 'NONE',
              name: 'None'
            },
            {
              id: 'ASSIGN_TO_CONSUMER',
              name: 'Auto assign to consumer users'
            },
            {
              id: 'ASSIGN_TO_CORPORATE',
              name: 'Auto assign to corporate users'
            }
          ]}
          name="autoAssignmentStrategy"
          value={this.state.record.autoAssignmentStrategy}
          onChange={value => {
            formHandler.handleInputChange('autoAssignmentStrategy', value);
          }}
        />

        <AppCheckboxInput
          style={{ marginTop: 20 }}
          name="isDefault"
          id="isDefaultField"
          htmlFor="isDefaultField"
          labelText="Is Default"
          onChange={value =>
            this.formHandler.handleInputChange('isDefault', value)
          }
          value={this.state.record.isDefault}
        />
      </FormSubmitWrapper>
    );
  }
}
