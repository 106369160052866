/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { AppPage, AppWidget } from '@launchpad/index';
import UserTierForm from '../../components/UserTierForm';

const UserTierAdd = () => (
  <AppPage title="Add user tier" icon="pencil">
    <div className="container">
      <AppWidget title="Add user tier" icon={null} padded>
        <UserTierForm />
      </AppWidget>
    </div>
  </AppPage>
);

export default UserTierAdd;
