import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AppTable from '@launchpad/components/crud/AppTable';
import FeeModel from '@launchpad/modules/fee/models/FeeModel';
import { AppFetcher, AppInfoList, ModalDialog } from '@launchpad/components';
import DeleteButton from '@launchpad/components/crud/DeleteButton';
import { AppBadge } from '@launchpad/components/typography';
import { currency, numberFormat } from '@launchpad/util/NumberHelper';
import FeeTypeModel from '../models/FeeTypeModel';
import CreateUpdateFeeForm from './CreateUpdateFeeForm';

const getColumns = () => {
  return [
    {
      name: 'id',
      label: 'Id',
      sortable: false,
      type: 'field'
    },
    {
      name: 'name',
      label: 'Name',
      sortable: false,
      type: 'field'
    },
    {
      name: 'collectionMethod',
      label: 'Collection method',
      sortable: false,
      type: 'field'
    },
    {
      name: 'fixedAmount',
      label: 'Fixed amount',
      sortable: false,
      type: 'field'
    },
    {
      name: 'rateAmount',
      label: 'Rate %',
      sortable: false,
      type: 'field'
    },
    {
      name: 'minAmount',
      label: 'Min amount',
      sortable: false,
      type: 'field'
    },
    {
      name: 'maxAmount',
      label: 'Max amount',
      sortable: false,
      type: 'field'
    },
    {
      name: 'currency',
      label: 'Currency',
      sortable: false,
      type: 'field'
    },
    {
      name: null,
      label: 'Actions',
      sortable: false,
      type: 'actions'
    }
  ];
};

const renderCell = (column, item, index) => {
  if (!item[column.name]) return '-';
};

const FeeTable = props => {
  const [showChargeFeeModal, setShowChargeFeeModal] = useState(false);
  const [showFeeDetailsModal, setShowFeeDetailsModal] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(1);
  const [currentFeeRecord, setCurrentFeeRecord] = useState<any>(null);

  const refreshTable = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  const renderActions = (fee, index) => {
    return (
      <div>
        <DeleteButton
          btnText="Delete"
          onConfirm={() => deleteFee(fee.id)}
          text={`Are you sure you wish to delete fee "${fee.name}"?`}
          style={{ marginTop: 5, marginLeft: 5, width: 80 }}
        />
        <button
          type="button"
          className="button"
          style={{ marginTop: 5, marginLeft: 5, width: 80 }}
          onClick={() => {
            setCurrentFeeRecord(fee);
            setShowChargeFeeModal(true);
          }}
        >
          Edit
        </button>
        <button
          type="button"
          className="button-info"
          style={{ marginTop: 5, marginLeft: 5, width: 80 }}
          onClick={() => {
            setCurrentFeeRecord(fee);
            setShowFeeDetailsModal(true);
          }}
        >
          Details
        </button>
      </div>
    );
  };

  const deleteFee = async feeId => {
    const result = await new FeeModel().deleteFee(feeId);
    refreshTable();
  };

  const addNewFee = () => {
    setShowChargeFeeModal(true);
    setCurrentFeeRecord(null);
  };

  return (
    <>
      <AppTable
        key={refreshCounter}
        icon="list"
        shouldRefresh={refreshCounter}
        title={props.title ?? 'Fees'}
        headerActions={
          <button
            type="button"
            onClick={() => addNewFee()}
            className="button"
            style={{ marginRight: '10px' }}
          >
            Add new
          </button>
        }
        params={{
          limit: 200,
          ...props.params
        }}
        filter={[
          {
            name: 'term',
            type: 'term'
          }
        ]}
        getTableColumns={() => getColumns()}
        renderCell={renderCell}
        hidePagination
        parseUrl={false}
        model={FeeModel}
        getColumnActions={renderActions}
      />
      <ModalDialog show={showChargeFeeModal}>
        <CreateUpdateFeeForm
          record={{
            entityId: props.params.entityId,
            entity: props.params.entity,
            feeGroupId: props.params.feeGroupId,
            ...(currentFeeRecord ?? {})
          }}
          customCloseFunction={() => {
            setShowChargeFeeModal(false);
          }}
          onSubmitSuccess={() => {
            refreshTable();
            setShowChargeFeeModal(false);
          }}
        />
      </ModalDialog>
      <ModalDialog
        show={showFeeDetailsModal}
        onClose={() => setShowFeeDetailsModal(false)}
      >
        <h3>{currentFeeRecord?.name}</h3>
        <hr />
        <div>
          <AppInfoList
            items={[
              {
                label: 'Id',
                value: currentFeeRecord?.id
              },
              {
                label: 'Name',
                value: currentFeeRecord?.name || '-'
              },
              {
                label: 'Description',
                value: currentFeeRecord?.description || '-'
              },
              {
                label: 'Collection method',
                value: currentFeeRecord?.collectionMethod || '-'
              },
              {
                label: 'Entity',
                value: currentFeeRecord?.entity || '-'
              },
              {
                label: 'Entity Id',
                value: currentFeeRecord?.entityId || '-'
              }
            ]}
          />
          <div className="separator" />
          <h5>Amounts</h5>
          <hr />
          <table className="table">
            <tbody>
              <tr className="tr-dark-bg">
                <td>Fixed amount</td>
                <td>Rate amount</td>
                <td>Min amount</td>
                <td>Max amount</td>
                <td>Currency</td>
                <td />
              </tr>
              <tr>
                <td>
                  {currentFeeRecord?.fixedAmount
                    ? currency(
                        currentFeeRecord.fixedAmount,
                        currentFeeRecord.currency
                      )
                    : '-'}
                </td>
                <td>
                  {currentFeeRecord?.rateAmount
                    ? numberFormat(currentFeeRecord.rateAmount, '0.00')
                    : '-'}
                </td>
                <td>
                  {currentFeeRecord?.minAmount
                    ? currency(
                        currentFeeRecord.minAmount,
                        currentFeeRecord.currency
                      )
                    : '-'}
                </td>
                <td>
                  {currentFeeRecord?.maxAmount
                    ? currency(
                        currentFeeRecord.maxAmount,
                        currentFeeRecord.currency
                      )
                    : '-'}
                </td>
                <td>{currentFeeRecord?.currency ?? '-'}</td>
                <td>
                  <AppBadge text="primary" />
                </td>
              </tr>
              {currentFeeRecord?.configuration?.secondaryAmounts
                ? currentFeeRecord?.configuration?.secondaryAmounts.map(x => (
                    <tr>
                      <td>
                        {x.fixedAmount
                          ? currency(x.fixedAmount, x.currency)
                          : '-'}
                      </td>
                      <td>
                        {x.rateAmount
                          ? numberFormat(x.rateAmount, '0.00')
                          : '-'}
                      </td>
                      <td>
                        {x.minAmount ? currency(x.minAmount, x.currency) : '-'}
                      </td>
                      <td>
                        {x.maxAmount ? currency(x.maxAmount, x.currency) : '-'}
                      </td>
                      <td>{x.currency ?? '-'}</td>
                      <td />
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
          <div className="separator" />
          <h5>Conditions</h5>
          <hr />
          <table className="table">
            <tbody>
              <tr className="tr-dark-bg">
                <td>Field</td>
                <td>Value</td>
              </tr>
              {currentFeeRecord?.configuration?.conditions
                ? currentFeeRecord?.configuration?.conditions.map(x => (
                    <tr>
                      <td>{x.field ?? '-'}</td>
                      <td>{x.value ?? '-'}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
          {currentFeeRecord ? (
            <>
              <div className="separator" />
              <AppFetcher
                model={FeeTypeModel}
                isIdInUrl
                id={currentFeeRecord.feeTypeId}
                renderNotFound={() => null}
                renderContent={record => (
                  <div style={{ marginTop: 20 }}>
                    <h5>Fee type</h5>
                    <hr />
                    <AppInfoList
                      items={[
                        {
                          label: 'Id',
                          value: record.id || '-'
                        },
                        {
                          label: 'Name',
                          value: record?.name || '-'
                        }
                      ]}
                    />
                  </div>
                )}
              />
            </>
          ) : null}
        </div>
        <div style={{ textAlign: 'center' }}>
          <button
            type="button"
            className="button"
            onClick={() => setShowFeeDetailsModal(false)}
          >
            Close
          </button>
        </div>
      </ModalDialog>
    </>
  );
};

export default FeeTable;
