import Api from '@launchpad/logic/api/Api';
import BaseModel from '@launchpad/logic/model/BaseModel';

export default class UserTierModel extends BaseModel {
  async deleteUserTier(userTier: string): Promise<void> {
    await Api.call(`admin/user-tiers/${userTier}`, 'delete');
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      name: {
        presence: {
          message: '^Please enter user tier name'
        }
      }
    };
  }

  /**
   * Get Method
   */
  getMethod(): string {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl(): any {
    return 'admin/user-tiers';
  }

  /**
   * Get details url
   */
  getSubmitUrl(): any {
    return `admin/user-tiers`;
  }

  /**
   * Get details url
   */
  getDetailsUrl(id: string): any {
    return `admin/user-tiers/${id}`;
  }

  /**
   * Get default table columns
   */
  getTableColumns(): any {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: false,
        type: 'field'
      },
      {
        name: 'name',
        label: 'Name',
        sortable: false,
        type: 'field'
      },
      {
        name: 'isDefault',
        label: 'Default',
        sortable: false,
        type: 'field'
      },
      {
        name: 'autoAssignmentStrategy',
        label: 'Auto assignment',
        sortable: false,
        type: 'field'
      },
      {
        name: 'status',
        label: 'Status',
        sortable: false,
        type: 'field'
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions'
      }
    ];
  }
}
