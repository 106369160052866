import { AppRoute } from '@launchpad';
import React from 'react';
import { Switch } from 'react-router-dom';
import UserTierAdd from './pages/add/UserTierAdd';
import UserTierDetails from './pages/details/UserTierDetails';
import UserTierEdit from './pages/edit/UserTierEdit';
import UserTiersList from './pages/UserTiersList';

export default class UserTiersNavigation extends React.PureComponent {
  render() {
    return (
      <Switch>
        <AppRoute path="/user-tiers/add-new" component={UserTierAdd} />
        <AppRoute path="/user-tiers/edit-tier/:id" component={UserTierEdit} />
        <AppRoute path="/user-tiers/:id" component={UserTierDetails} />
        <AppRoute path="/user-tiers" component={UserTiersList} />
      </Switch>
    );
  }
}
