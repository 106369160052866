/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import AppCircularProgress from '@launchpad/components/crud/app-circular-progress/app-circular-progress.component';
import { date } from '@launchpad/util/DateHelper';
import { camelCaseToHumanReadable } from '@launchpad/util/StringHelper';
import React, { useState } from 'react';

interface Props {
  title: string;
  arrayOfItems: Array<
    | { label: string; value: string; isDate?: boolean }
    | { table: Array<any>; label: string; columns?: Array<string> }
  >;
  repeatCheck?: () => void;
  isRepeatLoading?: boolean;
  onActionClicked?: (profileId: string) => void;
}

const ReportCollapsibleItem: React.FC<Props> = ({
  title,
  arrayOfItems,
  repeatCheck,
  onActionClicked,
  isRepeatLoading
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  // check if string is date
  const convertToDateIfStringIsDate = (
    d: string | number | boolean,
    isDate?: boolean
  ) => {
    if (typeof d === 'boolean') {
      return d ? 'Yes' : 'No';
    }

    if (typeof d === 'number') {
      return d;
    }
    const onlyNumbers = /^\d+$/.test(d);
    // check if d contains at least 3 dots (mm.dd.yyyy ) - temp solution to avoid converting string into date
    // console.log(new Date('7.22').toString()); // outputs "Mon Jan 01 7 06:00:00 GMT+0600 (Central Standard Time)"
    let containsDotsOrDashes = false;
    try {
      containsDotsOrDashes =
        d !== undefined && d !== null && (d.match(/\.|-/g) || []).length >= 2;
    } catch (error) {}

    // check if d contains at least 2 dots or 2 -
    if (
      !onlyNumbers &&
      containsDotsOrDashes &&
      new Date(d).toString() !== 'Invalid Date' &&
      isDate !== false
    ) {
      return date(d, 'DD/MM/YYYY');
    }
    return d;
  };

  // beautify json stringify output
  const beautifyJson = (json: string) => {
    const replacer = (key: string, value: any) => {
      if (typeof value === 'string') {
        return value.trim();
      }
      return value;
    };
    return JSON.stringify(JSON.parse(json), replacer, 2)
      .replace(/"([^"]+)":/g, '$1:')
      .replace(/"/g, '')
      .replace(/[{}[\]]/g, '');
  };

  const renderTablePreview = (table, label, columns) => {
    if (!table || table.length === 0) return null;

    const keys = columns || Object.keys(table[0]);
    return (
      <>
        {label && <span style={{ fontWeight: 'bold' }}>{label}:</span>}
        <table className="table">
          <tbody>
            <tr className="tr-dark-bg">
              {keys.map(key => {
                if (key === 'customDateOfBirth') {
                  return <td>{camelCaseToHumanReadable('dateOfBirth')}</td>;
                }
                if (!columns || columns.includes(key)) {
                  return <td>{camelCaseToHumanReadable(key)}</td>;
                }
                return null;
              })}
            </tr>
            {table.map(item => {
              // spread if item[key] is object or array
              return (
                <tr>
                  {keys.map(key => {
                    if (!columns || columns.includes(key)) {
                      if (key === 'customDateOfBirth') {
                        return (
                          <td>
                            {convertToDateIfStringIsDate(
                              `${item.birthDay || '-'}/${item.birthMonth ||
                                '-'}/${item.birthYear || '-'}`
                            )}
                          </td>
                        );
                      }
                      if (key === 'action') {
                        return (
                          <td>
                            <button
                              type="button"
                              onClick={event => {
                                event.stopPropagation();
                                if (onActionClicked) {
                                  onActionClicked(item.profileId);
                                }
                              }}
                              style={{ margin: 5, width: 90 }}
                              className="button-info btn-block"
                            >
                              Details
                            </button>
                          </td>
                        );
                      }
                      return (
                        <td>
                          {typeof item[key] === 'object' ||
                          Array.isArray(item[key])
                            ? beautifyJson(JSON.stringify(item[key]))
                            : convertToDateIfStringIsDate(item[key])}
                        </td>
                      );
                    }
                    return null;
                  })}
                </tr>
              );
              // // render each item value, item is object
              // return keys.map(key => <td>{item[key]}</td>);
            })}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <div>
      <div
        style={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 20
        }}
        onClick={toggleCollapse}
      >
        <h4>{title}</h4>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          {repeatCheck &&
            (isRepeatLoading ? (
              <div
                className="button-info btn-block"
                style={{
                  marginRight: 25,
                  minWidth: 127,
                  minHeight: 34,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <AppCircularProgress size={20} color="white" />
              </div>
            ) : (
              <button
                type="button"
                onClick={event => {
                  event.stopPropagation();
                  repeatCheck();
                }}
                style={{ marginRight: 25 }}
                className="button-info btn-block"
              >
                Repeat check
              </button>
            ))}
          <i className={`fa fa-chevron-${isCollapsed ? 'up' : 'down'}`} />
        </div>
      </div>
      <hr />
      {isCollapsed && (
        <dl>
          {arrayOfItems.map((item, index) => {
            if ('table' in item) {
              // handle table case
              return renderTablePreview(item.table, item.label, item.columns);
            }
            if (typeof item.value === 'object' && item.value !== null) {
              return (
                <div key={item.label + index}>
                  {Object.keys(item.value).map(key => (
                    <>
                      <dt>
                        {item.label} {camelCaseToHumanReadable(key)}:
                      </dt>
                      <dd>
                        <span key={key}>
                          {convertToDateIfStringIsDate(item.value[key])}
                        </span>
                      </dd>
                    </>
                  ))}
                </div>
              );
            }
            return (
              <div key={item.label + index}>
                <dt>{item.label}:</dt>
                <dd>
                  {convertToDateIfStringIsDate(item.value, item.isDate) || '-'}
                </dd>
              </div>
            );
          })}
        </dl>
      )}
    </div>
  );
};

export default ReportCollapsibleItem;
