import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FormHandler, FormSubmitWrapper, InputField } from '@launchpad';
import 'react-datepicker/dist/react-datepicker.css';
import AppSelect from '@launchpad/components/crud/AppSelect';
import CountryModel from '@launchpad/logic/model/CountryModel';
import AppNumberField from '@launchpad/components/elements/form/AppNumberField';
import UsersModel from '../../models/UsersModel';

export default class UserForm extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new UsersModel({
        id: props.id,
        firstName: '',
        lastName: '',
        email: '',
        mobileCountryCode: '44',
        mobileShort: '',
        birthDate: '',
        addressOne: '',
        addressTwo: '',
        city: '',
        postCode: ''
      })
    );
  }

  componentDidMount() {
    const { id } = this.props;
    if (id) {
      new UsersModel().get(id, true).then(result => {
        const [mcc, ms] =
          result.phoneNumber && result.phoneNumber.includes('|')
            ? result.phoneNumber.split('|')
            : ['', ''];
        this.setState({
          record: {
            ...result,
            mobileCountryCode: mcc.includes('+') ? mcc : `+${mcc}`,
            mobileShort: ms?.trim()
          }
        });
      });
    }
  }

  getSubmitData(record) {
    return {
      ...record,
      phoneNumber: `${record.mobileCountryCode?.replace('+', '')}|${
        record.mobileShort
      }`
    };
  }

  render() {
    const { formHandler } = this;
    return (
      <div>
        <h1>{this.props.title}</h1>
        <div
          className="element-with-blur"
          style={{ paddingTop: '2px', marginRight: 20 }}
        >
          <div className="dark-blur" />
          <div className="box-header">
            <h2>{this.props.title}</h2>
          </div>
          <div className="add-new-group" style={{ position: 'relative' }}>
            <div className="container">
              <FormSubmitWrapper
                formHandler={formHandler}
                to={`/user-details/${this.state.record.id}`}
              >
                <div className="row">
                  <div className="col-md-6">
                    <InputField
                      materialProps={{
                        fullWidth: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      customInput={TextField}
                      label="First Name"
                      type="text"
                      name="firstName"
                      value={this.state.record.firstName}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      label="Last Name"
                      type="text"
                      name="lastName"
                      value={this.state.record.lastName}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      label="Email"
                      type="email"
                      name="email"
                      value={this.state.record.email}
                      handler={formHandler}
                    />

                    <div className="row">
                      <div className="col-sm-7 input-wrap">
                        <AppSelect
                          textFieldProps={{
                            label: 'Country Code',
                            InputLabelProps: {
                              shrink: true
                            }
                          }}
                          model={CountryModel}
                          getOptionValue={country => `+${country.phoneCode}`}
                          getOptionLabel={country =>
                            `${country.name} (+${country.phoneCode})`
                          }
                          name="mobileCountryCode"
                          value={this.state.record.mobileCountryCode}
                          handler={formHandler}
                          placeholder="Search a Country"
                        />
                      </div>
                      <div className="col-sm-5 input-wrap-mobile">
                        <AppNumberField
                          // format="################"
                          label="Mobile"
                          type="tel"
                          name="mobileShort"
                          value={this.state.record.mobileShort?.trim()}
                          handler={formHandler}
                        />
                      </div>
                    </div>
                    <DatePicker
                      selected={
                        this.state.record.birthDate
                          ? moment(this.state.record.birthDate).toDate()
                          : ''
                      }
                      onChange={date =>
                        this.formHandler.handleInputChange(
                          'birthDate',
                          moment(date).format('YYYY-MM-DD')
                        )
                      }
                      maxDate={moment().subtract(18, 'years')._d}
                      style={{ width: '100%' }}
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={
                        <InputField
                          materialProps={{
                            fullWidth: true
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          inputProps={{}}
                          label="Birth day"
                          type="text"
                          value=""
                          name="birthDate"
                        />
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      materialProps={{
                        fullWidth: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      label="Address one"
                      type="text"
                      name="addressOne"
                      value={this.state.record.addressOne}
                      handler={formHandler}
                    />

                    <InputField
                      materialProps={{
                        fullWidth: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      label="Address two"
                      type="text"
                      name="addressTwo"
                      value={this.state.record.addressTwo}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      label="City"
                      type="text"
                      name="city"
                      value={this.state.record.city}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      label="Post Code"
                      type="text"
                      name="postCode"
                      value={this.state.record.postCode}
                      handler={formHandler}
                    />

                    <div className="input-wrap-country">
                      <AppSelect
                        textFieldProps={{
                          label: 'Country',
                          InputLabelProps: {
                            shrink: true
                          }
                        }}
                        model={CountryModel}
                        getOptionValue={country => country.iso3}
                        name="countryCode"
                        value={this.state.record.countryCode}
                        handler={formHandler}
                        placeholder="Search a Country"
                      />
                    </div>
                  </div>
                </div>
              </FormSubmitWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserForm.defaultProps = {
  title: '',
  id: null
};
