import { AppRoute } from '@launchpad';
import BaseModule from '@launchpad/logic/module/BaseModule';
import React from 'react';

export const NAME = 'users';

export default class OrganisationModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'organisation.*';
  }

  getPolicy() {
    return 'Admin:Organisation:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Companies',
        icon: 'building',
        path: '/users/organisations',
        permission: 'organisation.*',
        policy: 'Admin:Organisation:View:*'
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}users/organisations`}
        load={() => import('./OrganisationsNavigation')}
      />
    ];
  }
}
