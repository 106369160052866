// import { IModuleType } from '../../@launchpad/modules/ModulesService';
// import ChatModule from '@launchpad/modules/chat/ChatModule';
// import NotificationsModule from '@launchpad/modules/push/NotificationsModule';
// import LegalModule from '@launchpad/modules/legal/LegalModule';
// import ToolsModule from '@launchpad/modules/tools/ToolsModule';
import DashboardModule from '@launchpad/modules/lplite/dashboard/DashboardModule';
import UsersModule from '@launchpad/modules/lplite/users/UsersModule';
import TransactionsModule from '@launchpad/modules/lplite/transactions/TransactionsModule';
import OrganisationModule from '@launchpad/modules/lplite/organisations/OrganisationsModule';
import AccountsModule from './accounts/AccountsModule';
// import CardsModule from './cards/CardsModule';
import ReportsModule from './reports/ReportsModule';

const modules: any = [
  new DashboardModule(),
  new UsersModule(),
  new AccountsModule(),
  new OrganisationModule(),
  // new CardsModule(),
  new TransactionsModule(),
  // new ChatModule()
  new ReportsModule()
  // new LegalModule(),
  // new NotificationsModule()
  // new ToolsModule()
];

export default modules;
