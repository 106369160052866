import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AppTable from '@launchpad/components/crud/AppTable';
import BreadcrumbHelper from '../../../../../../@launchpad/util/BreadcrumbHelper';
import { currency } from '../../../../../../@launchpad/util/NumberHelper';
import { date } from '../../../../../../@launchpad/util/DateHelper';
import AccountTransactionsModel from '../../models/AccountTransactionsModel';

const AccountTransactions = props => {
  const [filters, setFilters] = useState([
    {
      name: 'term',
      type: 'term'
    }
  ]);

  BreadcrumbHelper.setCrumbs([
    {
      label: 'Account Transactions'
    }
  ]);

  function _downloadFile() {
    // TODO: Add url to download file
    alert('Downlaod csv');
    // Api.downloadFile('dictionary/download/xlsx');
  }

  function getColor(status) {
    if (status) {
      switch (true) {
        case status.toLowerCase() === 'failed':
        case status.toLowerCase() === 'fail':
        case status.toLowerCase() === 'declined':
        case status.toLowerCase() === 'decline':
        case status.toLowerCase() === 'voided':
        case status.toLowerCase() === 'unsuccsessful':
          return 'declined';
        case status.toLowerCase() === 'pending':
        case status.toLowerCase() === 'unknown':
        case status.toLowerCase() === 'refund_pending':
          return 'pending';
        case status.toLowerCase() === 'accepted':
        case status.toLowerCase() === 'success':
        case status.toLowerCase() === 'successful':
        case status.toLowerCase() === 'authorized':
        case status.toLowerCase() === 'settled':
        case status.toLowerCase() === 'authorization_reversed':
        case status.toLowerCase() === 'paid':
        case status.toLowerCase() === 'refunded':
          return 'load';
        default:
          return 'load';
      }
    }
    return 'load';
  }

  return (
    <div className="dashboard-management">
      <div className="d-flex justify-content-between align-items-center">
        <h1>
          <i className="fa fa-exchange" aria-hidden="true" /> Account
          Transactions
        </h1>
      </div>
      <AppTable
        icon="exchange"
        title="Account Transactions"
        params={{
          accountId: props.match.params.id ? props.match.params.id : undefined
        }}
        filter={[
          {
            name: 'term',
            type: 'term'
          }
        ]}
        renderCell={(column, item, index) => {
          if (column.name === 'cardPan') {
            if (!item.cardPan) return '- demo -';
            return (
              <Link
                className="userCardLink"
                to={`/cards/details/${item.cardId}`}
              >
                {item.cardPan}
              </Link>
            );
          }
          if (column.name === 'amount') {
            return currency(item.amount, item.currency);
          }
          if (column.name === 'postDate') {
            return date(item.postDate, 'DD / MM / Y HH:mm');
          }
          if (column.name === 'note') {
            if (!item.note) return '- pending -';
          }
        }}
        getRowClass={(item, index) => {
          if (!item) return null;

          if (item && item.status) {
            return getColor(item.status);
          }
        }}
        model={AccountTransactionsModel}
        getColumnActions={(member, index) => {
          return (
            <div>
              <Link
                to={`/transaction/details/${member.id}`}
                className="button-info"
              >
                Details
              </Link>
            </div>
          );
        }}
      />
    </div>
  );
};

export default AccountTransactions;
