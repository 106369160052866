import BaseModel from '../../../logic/model/BaseModel';

export default class FeeGroupModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/fee-groups';
  }

  /**
   * Get list url
   */
  getDetailsUrl(id) {
    return 'admin/fee-groups/' + id;
  }
}
