/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { AppIcon } from '@launchpad/components/typography';
import { camelCaseToHumanReadable } from '@launchpad/util/StringHelper';
import UploadDocumentModal from '@launchpad/modules/lplite/organisations/modals/UploadDocumentModal';
import { isArray } from 'lodash';
import {
  AppBadge,
  LoadingOverlay,
  ModalDialog,
  OverrideService
} from '@launchpad';
import ToastHelper from '@launchpad/util/ToastHelper';
import UsersModel from '../../../models/UsersModel';
import ReportCollapsibleItem from '../../../../components/ReportCollapsibleItem';

class CustomerKycInfo extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      isLoading: false,
      documentsUrls: [],
      kycTestsTableCollapsed: false
    };
  }

  componentDidMount() {
    this.pullKYCDocuments();
    this.pullReports();
  }

  onChangeUserStatusKyc(id, status) {
    this.setState({ isLoading: true });
    new UsersModel()
      .changeUserStatusKYC(id, status)
      .then(() => {
        this.props.onRefresh();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  toggleCollapse = name => {
    this.setState(prevState => ({ [name]: !prevState[name] }));
  };

  async pullKYCDocuments() {
    const { id } = this.props.match.params;

    await new UsersModel().getKycDocument(id).then(result => {
      if (result && result.publicUrls) {
        this.setState({
          documentsUrls: result.publicUrls
        });
      }
    });
  }

  async pullReports() {
    const { id } = this.props.match.params;

    await new UsersModel().getReports(id).then(result => {
      if (result && result.length > 0) {
        this.setState({ reports: result });
      }
    });
  }

  async repeatCheck(checkType, profileId = undefined, type = 'pep') {
    const { id } = this.props.match.params;
    this.setState({ isRepeatLoading: checkType });
    const userName = `${this.props.user.firstName?.toUpperCase()} ${this.props.user.lastName?.toUpperCase()}`;
    await new UsersModel()
      .triggerCheck(id, userName, checkType)
      .then(result => {
        // Skip this if user fetched details for pep check
        if (!profileId) {
          this.pullUboReports();
          ToastHelper.show(
            `You have successfully repeat ${checkType} check`,
            'success'
          );
        } else {
          // eslint-disable-next-line no-lonely-if
          if (type === 'pep') {
            // show modal with result information
            this.setState({
              pepDetails:
                result?.result?.results?.profileDetailsResult
                  ?.pepDeskDetailsResult?.functionDetails || [],
              showPepDetailsModal: true
            });
          } else {
            // type === 'sis'
            this.setState({
              sisDetails:
                result?.result?.results?.profileDetailsResult
                  ?.sisPlusDetailsResult,
              showSisDetailsModal: true
            });
          }
        }
      })
      .finally(() => this.setState({ isRepeatLoading: undefined }));
  }

  renderResult(status) {
    switch (status.toLowerCase()) {
      case 'passed':
        return <AppBadge text={status} icon="check" />;
      case 'fail':
      case 'failed':
      case 'decline':
        return <AppBadge text={status} icon="times" type="danger" />;
      case 'refer':
        return <AppBadge text={status} icon="clock-o" type="info" />;
      default:
        return <AppBadge text={status} icon="clock-o" type="info" />;
    }
  }

  render() {
    const { user } = this.props;

    if (this.state.redirect) {
      return <Redirect to={`/user-details/${user.id}`} />;
    }
    const kycStatus = user.kycStatus || '';
    const newKycStatus = kycStatus.replace(/#|_/g, ' ');
    const userKycStatus = user.kycStatus ? user.kycStatus.toUpperCase() : '';

    return (
      <div className="main" key={user}>
        <div className="dark-blur" />
        <div className="customers-info">
          <div className="customer-details">
            <div>
              <h2 className="customer-name">
                <span className="company">
                  {user.firstName} {user.lastName}
                </span>
              </h2>
            </div>
            {/* <div>
              <div className="bottom" style={{ alignItems: 'center' }}>
                <Link
                  to={`/users/all-users/edit-user/${user.id}`}
                  className="button-info"
                  style={{ marginRight: 20 }}
                >
                  Edit
                </Link>
              </div>
            </div> */}
          </div>

          <div className="customers-main">
            <div className="row">
              <div className="col">
                {!user || this.state.isLoading ? (
                  <LoadingOverlay />
                ) : (
                  <div className="padded">
                    <div className="row text-white">
                      <div style={{ marginBottom: 20 }} className="col-lg-6">
                        <h4>KYC</h4>
                        <hr />
                        <dl>
                          <dt>Status:</dt>
                          <dd>{newKycStatus.toUpperCase()}</dd>
                        </dl>
                        <dl>
                          <dt>Change KYC status:</dt>
                          <dd>
                            <select
                              className="option-wrapper"
                              onChange={event =>
                                this.onChangeUserStatusKyc(
                                  user.id,
                                  event.target.value
                                )
                              }
                              value={
                                userKycStatus !== 'UNVERIFIED'
                                  ? userKycStatus
                                  : ''
                              }
                            >
                              <option disabled value="">
                                -- select an option --
                              </option>
                              <option value="PENDING_DATA_RESUBMISSION">
                                Request re-entry of personal details
                              </option>
                              <option value="PENDING_FRONTEND_VERIFICATION">
                                Pending documents
                              </option>
                              <option value="VERIFICATION_IN_PROGRESS">
                                Verification in progress
                              </option>
                              <option value="VERIFIED">Verified</option>
                              <option value="REJECTED">Rejected</option>
                            </select>
                          </dd>
                        </dl>

                        {this.state.reports?.map(report =>
                          // if format is not in the expected format, skip it
                          !report.response?.items ? null : (
                            <ReportCollapsibleItem
                              key={report.id}
                              title={report.response?.headerName}
                              repeatCheck={() => this.repeatCheck(report.type)}
                              isRepeatLoading={
                                this.state.isRepeatLoading === report.type
                              }
                              arrayOfItems={Object.entries(
                                report.response.items
                              ).map(([key, value]) => {
                                if (
                                  isArray(value) &&
                                  value.length > 0 &&
                                  typeof value[0] === 'object'
                                ) {
                                  return {
                                    table: value,
                                    label: camelCaseToHumanReadable(key),
                                    columns: Object.keys(value[0])
                                  };
                                }
                                return {
                                  label: camelCaseToHumanReadable(key),
                                  value
                                };
                              })}
                            />
                          )
                        )}
                      </div>
                      <div style={{ marginBottom: 20 }} className="col-lg-6">
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <h4 style={{ margin: 0 }}>
                            KYC Documents Submission
                          </h4>
                          {OverrideService.getConfig()
                            .enableCustomerDocumentUpload && (
                            <>
                              <div
                                onClick={() =>
                                  this.setState({
                                    showUploadDocumentModal: true
                                  })
                                }
                                className="button"
                              >
                                <i className="fa fa-fw fa-plus" />
                                Upload new
                              </div>
                              <UploadDocumentModal
                                showModal={this.state.showUploadDocumentModal}
                                documentTypes={[
                                  { value: 'PASSPORT', label: 'Passport' },
                                  {
                                    value: 'DRIVINGLICENCE',
                                    label: 'Driving Licence'
                                  },
                                  { value: 'SELFIE', label: 'Selfie' },
                                  { value: 'POA', label: 'Proof of address' },
                                  { value: 'OTHER', label: 'Other' }
                                ]}
                                userId={user.id}
                                onClose={() => {
                                  this.setState({
                                    showUploadDocumentModal: false
                                  });
                                  this.pullKYCDocuments();
                                }}
                              />
                            </>
                          )}
                        </div>
                        <hr />
                        <div className="d-flex align-items-center justify-content-center flex-column">
                          {this.state.documentsUrls.length ? (
                            this.state.documentsUrls.map(item => {
                              return (
                                <div
                                  className="d-flex align-items-center flex-column"
                                  key={item.publicUrl}
                                >
                                  <span>Document type: {item.type}</span>
                                  {item.imageType.includes('pdf') ? (
                                    <a
                                      href={item.publicUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        marginTop: 10,
                                        marginBottom: 40,
                                        cursor: 'pointer'
                                      }}
                                    >
                                      <AppIcon
                                        reactOnHover
                                        name="fa.file-text"
                                        style={{ fontSize: 60, color: 'white' }}
                                      />
                                    </a>
                                  ) : (
                                    <>
                                      <img
                                        src={item.publicUrl}
                                        style={{
                                          width: '100%',
                                          height: 'auto',
                                          maxWidth: 300,
                                          marginTop: 10,
                                          marginBottom: 40,
                                          cursor: 'pointer'
                                        }}
                                        alt="document"
                                        onClick={() =>
                                          this.openModal(item.publicUrl)
                                        }
                                      />
                                      <ModalDialog
                                        show={this.state.isModalOpen}
                                        onClose={this.closeModal}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column'
                                          }}
                                        >
                                          <img
                                            src={this.state.previewImageUrl}
                                            alt="Preview"
                                            style={{ width: 770 }}
                                          />
                                          <button
                                            type="button"
                                            onClick={() => this.closeModal()}
                                            className="button-info"
                                            style={{ marginTop: 20 }}
                                          >
                                            Close
                                          </button>
                                        </div>
                                      </ModalDialog>
                                    </>
                                  )}
                                </div>
                              );
                            })
                          ) : (
                            <h4 className="m-3">No KYC documents</h4>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CustomerKycInfo);
