import { AppRoute } from '@launchpad';
import React from 'react';
import { Switch } from 'react-router-dom';
import UserDetails from './details/UserDetails';
import EditUser from './edit/EditUser';

export default class UsersNavigation extends React.PureComponent {
  render() {
    return (
      <Switch>
        <AppRoute path="/user-details/:id/edit" component={EditUser} />
        <AppRoute path="/user-details/:id" component={UserDetails} />
      </Switch>
    );
  }
}
