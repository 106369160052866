import { AppPage } from '@launchpad';
import AppTable from '@launchpad/components/crud/AppTable';
import DeleteButton from '@launchpad/components/crud/DeleteButton';
import BreadcrumbHelper from '@launchpad/util/BreadcrumbHelper';
import { toHumanString } from '@launchpad/util/StringHelper';
import ToastHelper from '@launchpad/util/ToastHelper';
import React, { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import UserTierModel from '../models/UserTierModel';

const parseAutoAssignmentStrategy = (strategy: string): string => {
  switch (strategy) {
    case 'ASSIGN_TO_CONSUMER':
      return 'Assign to consumer';
    case 'ASSIGN_TO_CORPORATE':
      return 'Assign to corporate';
    case 'NONE':
    case null:
    case undefined:
      return '-';
    default:
      return strategy;
  }
};

const UserTiersList = (): ReactElement => {
  const [refreshCounter, setRefreshCounter] = useState(1);

  useEffect(() => {
    BreadcrumbHelper.setCrumbs([
      {
        label: 'User tiers'
      }
    ]);
  }, []);

  const refreshTable = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  const deleteUserTier = async userTierId => {
    try {
      await new UserTierModel().deleteUserTier(userTierId);
      refreshTable();
    } catch (e) {
      if (e && (e as any).errors) {
        ToastHelper.show((e as any).errors.map(x => x.message).join(' '));
      }
    }
  };

  return (
    <AppPage title="User tiers" icon="users">
      <AppTable
        icon="users"
        title="Users"
        key={refreshCounter}
        filter={[
          {
            name: 'term',
            type: 'term'
          }
        ]}
        order={[
          {
            field: 'id',
            direction: 'desc'
          }
        ]}
        model={UserTierModel}
        renderCell={(column, item): ReactElement | string | undefined => {
          switch (column.name) {
            case 'isDefault':
              return (
                <i
                  className={`fa fa-fw fa-${
                    item.isDefault ? 'check' : 'times'
                  }`}
                >
                  {' '}
                </i>
              );
            case 'autoAssignmentStrategy':
              return parseAutoAssignmentStrategy(item.autoAssignmentStrategy);
            default:
              return undefined;
          }
        }}
        getColumnActions={(tier: { id: string; name: string }) => {
          return (
            <div>
              <Link to={`/user-tiers/${tier.id}`} className="button-info">
                Details
              </Link>
              <Link
                to={`/user-tiers/edit-tier/${tier.id}`}
                className="button-info"
              >
                Edit
              </Link>
              <DeleteButton
                onConfirm={() => deleteUserTier(tier.id)}
                text={`Are you sure you wish to delete user tier "${tier.name}"?`}
              />
            </div>
          );
        }}
        headerActions={
          <Link
            to="/user-tiers/add-new"
            className="button width-120"
            style={{ marginRight: '10px' }}
          >
            <i className="fa fa-fw fa-plus" />
            Add new
          </Link>
        }
      />
    </AppPage>
  );
};

export default UserTiersList;
