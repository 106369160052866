import React from 'react';
import FeeTable from '@launchpad/modules/fee/components/FeeTable';

const UserTierFees = props => {
  return (
    <>
      {props.userTier.feeGroupId ? (
        <>
          <FeeTable
            title="Fees from fee group"
            params={{
              feeGroupId: props.userTier.feeGroupId
            }}
          />
          <div style={{ marginBottom: 20 }} />
        </>
      ) : null}
      <FeeTable
        title="Fees attached to the tier directly"
        params={{
          entity: 'USER_TIER',
          entityId: props.userTier.id
        }}
      />
    </>
  );
};

export default UserTierFees;
