/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Api from '@launchpad/logic/api/Api';
import Chart from 'chart.js';
import { LoadingOverlay } from '@launchpad';

function NewUsersTrendWidget(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    Chart.defaults.global.defaultFontColor = 'white';
    // Load data and initialize graph
    _loadData();
  }, []);

  const _loadData = async () => {
    const result: any = await Api.call('admin/users/stats/trend', 'GET');

    if (result.success) {
      setLoading(false);
      renderOrderDotsChart(result.data ?? []);
    }
  };

  const renderOrderDotsChart = (data: {
    labels: Array<string>;
    data: Array<string>;
  }) => {
    const config: any = {
      type: 'line',
      data: {
        labels: data.labels,
        datasets: [
          {
            label: '# of Users',
            data: data.data,
            backgroundColor: 'rgba(54, 162, 235, 1)',
            borderColor: 'rgba(54, 162, 235, 0.4)',
            pointBorderColor: 'rgba(255, 255, 255, 1)',
            borderWidth: 2,
            fill: false,
            showLine: true,
            borderDash: [2],
            pointRadius: 6,
            pointHoverRadius: 10
          }
        ]
      },
      options: {
        responsive: true,
        legend: {
          display: false
        },
        hover: {
          mode: 'index'
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                color: 'rgba(255, 255, 255, 0.1)'
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Month'
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                color: 'rgba(255, 255, 255, 0.1)'
              },
              display: true,
              scaleLabel: {
                display: false
              }
            }
          ]
        }
      }
    };

    const canvas = document.getElementById(
      'chart-dots'
    ) as HTMLCanvasElement | null;

    const ctx = canvas?.getContext('2d') as CanvasRenderingContext2D;

    window.myScatter = new Chart(ctx, config);
  };

  return (
    <div className="widget">
      <div className="dark-blur" />
      <div className="widget-header">
        <h3 className="widget-title">
          <i className="fa fa-bar-chart" aria-hidden="true" /> New users
        </h3>
      </div>
      <div className="widget-inner" style={{ padding: '10px' }}>
        {loading ? (
          <LoadingOverlay />
        ) : (
          <div>
            <canvas
              id="chart-dots"
              style={{ width: '100%', height: '300px' }}
              width="600"
              height="300"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default NewUsersTrendWidget;
