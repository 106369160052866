import React, { useEffect, useMemo, useState } from 'react';
import imageCompression from 'browser-image-compression';
import { useDropzone } from 'react-dropzone';
import { Trans } from 'react-i18next';
import { AppIcon } from '@launchpad';
import AppCircularProgress from '@launchpad/components/crud/app-circular-progress/app-circular-progress.component';

const baseStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '20px 30px',
  borderWidth: 1,
  borderRadius: 8,
  borderColor: '#858994',
  borderStyle: 'dashed',
  backgroundColor: 'white',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  // borderColor: theme.MAIN_COLOR,
  borderColor: 'green',
  borderStyle: 'dashed'
};

const acceptStyle = {
  borderColor: 'green'
};

const rejectStyle = {
  borderColor: 'red'
};

const UploadKycDocument: React.FC<{
  allowPdf: boolean;
  onSelectFile: (file: any) => void;
  onClearFile: () => void;
}> = ({ allowPdf, onSelectFile, onClearFile }) => {
  const [selectedFile, setSelectedFile] = useState<any>();
  const [base64File, setBase64File] = useState<any>();
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedFile) {
      onSelectFile(selectedFile);
    } else {
      onClearFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: allowPdf ? ['image/*', 'application/pdf'] : ['image/*'],
    onDrop: files => {
      setIsImageLoading(true);
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 900,
        useWebWorker: false,
        fileType: 'image/png'
      };

      if (files[0]?.type.startsWith('image/')) {
        imageCompression
          .getDataUrlFromFile(files[0])
          .then(base64 => {
            setBase64File(base64);
          })
          .then(() => {
            imageCompression(files[0], options).then(file => {
              setIsImageLoading(false);
              setSelectedFile(file);
              // dispatch({ type: SET_KYC_DOC_DATA, kycDocData: { file } });
            });
          });
      } else if (files[0]?.type === 'application/pdf') {
        setIsImageLoading(false);
        setSelectedFile(files[0]);
        // dispatch({ type: SET_KYC_DOC_DATA, kycDocData: { file: files[0] } });
      } else {
        setIsImageLoading(false);
      }
    }
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {!selectedFile ? (
        <div
          style={{
            width: 240,
            height: 240,
            marginBottom: 20,
            border: '1px solid white'
          }}
        >
          {isImageLoading ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%'
              }}
            >
              <AppCircularProgress color="white" />
            </div>
          ) : (
            <>
              <div
                {...getRootProps({ style })}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  height: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div />
                <input {...getInputProps()} />
                <em
                  className="fa fa-fw fa-upload"
                  style={{ fontSize: '4em' }}
                />
                {/* <AppIcon name="upload-image" size={50} color="white" /> */}
                <div style={{ textAlign: 'center' }}>
                  <p
                    style={{
                      textDecoration: 'underline',
                      color: 'white',
                      marginBottom: 0
                    }}
                  >
                    <Trans>Browse your file</Trans>
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    <Trans>or Drag & Drop it here</Trans>
                  </p>
                </div>
              </div>
              {/* <p
            style={{
              width: '50%',
              textAlign: 'center',
              margin: 'auto',
              fontSize: 10,
              marginBottom: 0
            }}
            >
            <Trans>
            Supports: JPG, PNG, {allowPdf && 'PDF,'} GIF Max of 5mb per file
            </Trans>
          </p> */}
            </>
          )}
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: 8,
            alignItems: 'center'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {base64File ? (
              <img
                src={base64File}
                alt="file"
                style={{
                  maxHeight: '200px',
                  borderRadius: 8,
                  maxWidth: '200px'
                }}
              />
            ) : (
              <AppIcon
                reactOnHover
                name="fa.file-text"
                style={{ fontSize: 60, color: 'white' }}
              />
            )}
          </div>
          <span style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20 }}>
            {selectedFile.name}
          </span>
          <div
            style={{ cursor: 'pointer', marginTop: 20, marginBottom: 20 }}
            onClick={() => {
              setSelectedFile(null);
              setBase64File(null);
            }}
          >
            <AppIcon
              name="fa.times-circle"
              style={{
                fontSize: 20,
                color: '#b74343'
              }}
            />
            <span>Clear</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadKycDocument;
