import overrides from './overrides';
import styles from './styles';
import config from './config';
import modules from './modules';
import { getNavigation } from './navigation';

const configuration = {
  overrides,
  styles,
  config,
  modules,
  getNavigation
};

export default configuration;
