import { AccountProductTypeModel } from '@launchpad/logic/model/ProductTypeModel';
import {
  Checkbox,
  Box,
  Chip,
  ListItemText,
  MenuItem,
  Select
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const conditionOptions = [
  {
    title: 'Account currency',
    value: 'account.currency'
  },
  {
    title: 'Account product',
    value: 'account.productId'
  }
];

const FeeConditions = props => {
  const conditions = props.fee?.configuration?.conditions
    ? props.fee?.configuration?.conditions
    : [];

  const setConditions = async newConditions => {
    props.onChange(newConditions);
  };

  const addNewCondition = async () => {
    setConditions([...conditions, {}]);
  };

  const removeItem = (item, index) => {
    const newConditions = JSON.parse(JSON.stringify(conditions));
    setConditions([
      ...newConditions.slice(0).filter((x, ind) => {
        return ind !== index;
      })
    ]);
  };

  const handleInputChange = async (item, index, field, value) => {
    const newItems = conditions.map((x, i) => {
      if (i === index) {
        return {
          ...x,
          [field]: value
        };
      }

      return x;
    });

    setConditions(newItems);
  };

  return (
    <div className="fee-amounts">
      <h6>Fee conditions </h6>
      <table className="table compact-table">
        <tr>
          <th style={{ width: '40%' }}>Field</th>
          <th style={{ width: '40%' }}>Value</th>
          <th style={{ width: '40%' }}>Actions</th>
        </tr>
        {conditions.map((x, index) => (
          <ConditionRow
            onChange={(item, field, value) =>
              handleInputChange(item, index, field, value)
            }
            item={x}
            index={index}
            removeItem={(item, ind) => removeItem(item, ind)}
          />
        ))}
      </table>
      <div className="text-center" style={{ width: '100%' }}>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => addNewCondition()}
        >
          Add
        </button>
      </div>
    </div>
  );
};

const getErrorProps = (allErrors, fieldName) => {
  const fieldErrors = allErrors?.filter(x => x.field === fieldName);

  if (!fieldErrors || fieldErrors.length === 0) return null;

  return {
    helperText: fieldErrors.map(x => x.message).join(','),
    error: true
  };
};

const ConditionRow = props => {
  const {
    errors,
    index,
    item: { field, value }
  } = props;

  return (
    <tr>
      <td>
        <Select
          MenuProps={{
            style: { zIndex: 999999999 }
          }}
          style={{
            width: '100%'
          }}
          defaultValue={field ?? ''}
          value={field ?? ''}
          name={`[${index}].field`}
          {...getErrorProps(errors, `[${index}].field`)}
          onChange={e => props.onChange(props.item, 'field', e.target.value)}
        >
          <MenuItem value="">-please select-</MenuItem>
          {conditionOptions.map(x => (
            <MenuItem value={x.value}>{x.title}</MenuItem>
          ))}
        </Select>
      </td>
      <td>
        <ConditionValueSelector
          field={field}
          value={value}
          onChange={selectedValue =>
            props.onChange(props.item, 'value', selectedValue)
          }
        />
      </td>
      <td>
        <button
          type="button"
          className="btn btn-danger btn-sm"
          onClick={() => props.removeItem(props.item, props.index)}
        >
          Remove
        </button>
      </td>
    </tr>
  );
};

const ConditionValueSelector = props => {
  switch (props.field) {
    case 'account.currency':
      return <AccountCurrencySelection {...props} />;
    case 'account.productId':
      return <ProductIdSelector {...props} />;
    default:
      return null;
  }
};

const AccountCurrencySelection = props => {
  const { value, index, errors } = props;
  return (
    <Select
      MenuProps={{
        style: { zIndex: 999999999 }
      }}
      style={{
        width: '100%'
      }}
      defaultValue={value ?? ''}
      value={value ?? ''}
      name={`[${index}].value`}
      {...getErrorProps(errors, `[${index}].value`)}
      onChange={e => props.onChange(e.target.value)}
    >
      <MenuItem value="">-please select-</MenuItem>
      <MenuItem value="GBP">GBP</MenuItem>
      <MenuItem value="EUR">EUR</MenuItem>
      <MenuItem value="USD">USD</MenuItem>
    </Select>
  );
};

type Product = {
  id: string;
  internalReference: string;
  name;
  string;
};

const ProductIdSelector = props => {
  const { value, index, errors } = props;
  const [products, setProducts] = useState<Product[]>([]);

  const currentValue = value ?? [];
  const fieldName = `[${index}].productId`;

  const loadProducts = async () => {
    const response: {
      data: Product[];
    } = (await new AccountProductTypeModel().fetch(null)) as {
      data: Product[];
    };

    setProducts(response.data);
  };

  useEffect(() => {
    loadProducts().catch(console.error);
  }, []);

  return (
    <Select
      MenuProps={{
        style: { zIndex: 999999999 }
      }}
      style={{
        width: '100%'
      }}
      multiple
      defaultValue={currentValue}
      value={currentValue}
      name={fieldName}
      {...getErrorProps(errors, fieldName)}
      onChange={e => props.onChange(e.target.value)}
      renderValue={selected => (
        <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
          {(selected as string[]).map((selectedValue: string) => (
            <Chip
              key={selectedValue}
              label={
                products.find(x => x.id === selectedValue)?.internalReference
              }
            />
          ))}
        </Box>
      )}
    >
      {products?.map(x => (
        <MenuItem key={x.id} value={x.id}>
          <Checkbox checked={value?.indexOf(x.id) > -1} />
          <ListItemText primary={`${x.name} (${x.internalReference})`} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default FeeConditions;
