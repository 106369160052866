/* eslint-disable no-nested-ternary */
import {
  AppFetcher,
  LoadingOverlay,
  LPOverridable
} from '@launchpad/components';
import FeeGroupModel from '@launchpad/modules/fee/models/FeeGroupModel';
import React from 'react';

const UserTierBasicInfo = props => {
  const { userTier } = props;

  if (!userTier.id) {
    return <LoadingOverlay />;
  }

  return (
    <div className="main" key={userTier.id}>
      <div className="dark-blur" />
      <div className="customers-info">
        <div className="customer-details">
          <div>
            <h2 className="customer-name">
              <span className="company">{userTier.name}</span>
            </h2>
          </div>
          {/* <div>
              <div className="bottom" style={{ alignItems: 'center' }}>
                <Link
                  to={`/users/all-users/edit-user/${user.id}`}
                  className="button-info"
                  style={{ marginRight: 20 }}
                >
                  Edit
                </Link>
              </div>
            </div> */}
        </div>
        <div className="customers-main">
          <div className="row">
            <div className="col">
              <div className="padded">
                <LPOverridable
                  name="userTier.details.basic.before"
                  userTier={userTier}
                />
                <div className="row text-white">
                  <div style={{ marginBottom: 20 }} className="col-lg-6">
                    <h4>Basic Info</h4>
                    <hr />
                    <dl>
                      <dt>Name:</dt>
                      <dd>{userTier.name || '-'}</dd>
                      <dt>Id:</dt>
                      <dd>{userTier.id || '-'}</dd>
                      <dt>Status:</dt>
                      <dd>{userTier.status || '-'}</dd>
                      <dt>Created at:</dt>
                      <dd>{userTier.createdAt || '-'}</dd>
                      <dt>Updated at:</dt>
                      <dd>{userTier.updatedAt || '-'}</dd>
                      {/* <dt>Last name:</dt>
                        <dd>{user.lastName || '-'}</dd>
                        <dt>Username:</dt>
                        <dd>{user.username ? user.username : '-'}</dd>
                        <dt>E-mail address:</dt>
                        <dd>{user.email || '-'}</dd>
                        <dt>Customer id:</dt>
                        <dd>{user.id || '-'}</dd>
                        <dt>Birth date:</dt>
                        <dd>{date(user.birthDate, 'D / MMMM / Y') || '-'}</dd>
                        <dt>Onboarding:</dt>
                        <dd>
                          {date(user.registeredAt, 'D / MMMM / Y HH:mm') || '-'}
                        </dd> */}
                    </dl>
                  </div>
                  <div style={{ marginBottom: 20 }} className="col-lg-6">
                    <h4>Fee group</h4>
                    <hr />
                    {userTier.feeGroupId ? (
                      <AppFetcher
                        model={FeeGroupModel}
                        isIdInUrl={true}
                        id={userTier.feeGroupId}
                        renderContent={record => (
                          <dl>
                            <dt>Name:</dt>
                            <dd>{record.name || '-'}</dd>
                            <dt>Id:</dt>
                            <dd>{record.id || '-'}</dd>
                            <dt>Description:</dt>
                            <dd>{record.description || '-'}</dd>
                          </dl>
                        )}
                      />
                    ) : (
                      <em>- not configured - </em>
                    )}
                  </div>
                  {/* <div style={{ marginBottom: 20 }} className="col-lg-6">
                    <h4>User errors</h4>
                    <hr />
                    {user.userErrors && user.userErrors.length > 0 ? (
                      user.userErrors.map(e => (
                        <>
                          <span>{e.errorMessage || '-'}</span>
                          <br />
                          <br />
                        </>
                      ))
                    ) : (
                      <span>No errors</span>
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTierBasicInfo;
