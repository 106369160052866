/* eslint-disable react/no-unused-state */
import AppUploadField from '@launchpad/components/crud/AppUploadField';
import React from 'react';
import { withRouter } from 'react-router';
import { FormHandler } from '@launchpad/components';
import { OverrideService } from '@launchpad/logic/services';
import ConfirmationModalDialog from '@launchpad/components/modal/ConfirmationModalDialog';
import ToastHelper from '@launchpad/util/ToastHelper';
import Api from '@launchpad/logic/api/Api';
import axios from 'axios';
import Config from '../../../../../config';
import BulkLoadUsersModel from '../../bulk-load/models/BulkLoadUsersModel';

class BatchPaymentRequests extends React.Component {
  constructor(props) {
    super(props);

    this.formHandler = new FormHandler(
      this,
      new BulkLoadUsersModel(
        {
          name: '',
          bulkFile: '',
          userId: props.user.id
        },
        {
          finalFeeAmount: '',
          showInfoModal: false,
          removeFirstUpload: false,
          file: {}
        }
      )
    );
  }

  onFileUploaded(file, response) {
    if (response.success) {
      this.setState({
        finalFeeAmount: response.data.finalFeeAmount,
        showInfoModal: true,
        file
      });
    }
  }

  onClose() {
    this.setState(
      {
        showInfoModal: false,
        removeFirstUpload: true,
        finalFeeAmount: '',
        file: {}
      },
      () => {
        this.props.onRefresh();
        this.setState({
          removeFirstUpload: false
        });
      }
    );
  }

  async _startUploadingAgainOnAnotherEP() {
    const data = new FormData();
    data.append('file', this.state.file.file);
    data.append('filename', this.state.file.name);
    data.append('userId', this.props.user.id);

    axios
      .post(
        `${Config.url}${OverrideService.getOverrides()[
          'batch-payment-requests-url'
        ] || 'admin/transaction/bulk-transactions'}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${Api.resolveToken()}`,
            'Provided-Device-Id': Api.getProvidedDeviceId()
          }
        }
      )
      .then(response => {
        if (response.data.success) {
          ToastHelper.show(
            'You have successfully completed uploading batch payment requests.',
            'success'
          );
          this.onClose();
        } else {
          ToastHelper.show('Something went wrong.');
        }
      })
      .catch(error => {
        const errors = error?.response?.data.errors ?? [];
        if (errors && errors.length > 0) {
          ToastHelper.show(`Error: ${errors.map(x => x.message).join(';')}`);
        } else {
          ToastHelper.show('Something went wrong.');
        }
      });
  }

  render() {
    const { formHandler } = this;

    return (
      <div className="main">
        <div className="dark-blur" />
        <div className="customers-info">
          <div className="customer-details">
            <div>
              <h2 className="customer-name">
                <span className="company">Upload batch payment requests</span>
              </h2>
            </div>
          </div>

          <div className="customers-main py-5 col-6">
            <AppUploadField
              key={this.state.removeFirstUpload}
              label="*Upload PAYMENT REQUESTS CSV file"
              name="bulkFile"
              handler={formHandler}
              submitUrl={
                OverrideService.getOverrides()[
                  'confirm-batch-payment-requests-url'
                ] || 'admin/transaction/confirm-bulk-transactions'
              }
              errors={formHandler.getErrors()}
              accept=".csv"
              value={this.state.record.bulkFile}
              additionalData={{ name: 'userId', value: this.props.user.id }}
              onFileUpload={(file, response) =>
                this.onFileUploaded(file, response)
              }
            />
            <p className="text-center">
              Example file can be downloaded{' '}
              <a
                href={require('../../bulk-load/examples/batch-payment-template.csv')}
                style={{ fontWeight: 'bold' }}
                target="_blank"
                download
                rel="noreferrer"
              >
                here
              </a>
            </p>
          </div>
        </div>
        <ConfirmationModalDialog
          show={this.state.showInfoModal}
          text={`Your final Fee Amount for these payment requests is ${this.state.finalFeeAmount}. Please confirm if you agree. In case you do not want to continue, press the cancel button.`}
          onModalClose={() => this.onClose()}
          onSubmit={() => this._startUploadingAgainOnAnotherEP()}
        />
      </div>
    );
  }
}

export default withRouter(BatchPaymentRequests);
