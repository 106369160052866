import { AppFetcher, AppPage } from '@launchpad';
import AppTabs from '@launchpad/components/crud/tabs/AppTabs';
import BreadcrumbHelper from '@launchpad/util/BreadcrumbHelper';
import React, { useEffect, useState } from 'react';
import UserTierModel from '../../models/UserTierModel';
import UserTierBasicInfo from './tabs/UserTierBasicInfo';
import UserTierFees from './tabs/UserTierFees';
import UserTierUsers from './tabs/UserTierUsers';

const getTabs = record => {
  return [
    {
      id: 'basic',
      icon: 'info',
      title: 'Basic Info',
      component: <UserTierBasicInfo userTier={record} />
    },
    {
      id: 'users',
      icon: 'users',
      title: 'Users',
      component: <UserTierUsers userTier={record} />
    },
    {
      id: 'fees',
      icon: 'money',
      title: 'Fees',
      component: <UserTierFees userTier={record} />
    }
  ];
};

const UserTierDetails = props => {
  let _fetcher: AppFetcher | null = null;

  const [activeTab, setActiveTab] = useState('basic');

  useEffect(() => {
    BreadcrumbHelper.setCrumbs([
      {
        label: 'User tiers',
        link: '/user-tiers'
      }
    ]);
  }, []);

  const renderContent = record => {
    BreadcrumbHelper.setLastCrumb(`User tier details: ${record.name}`);
    return (
      <AppTabs
        activeTab={activeTab}
        tabs={getTabs(record)}
        onTabChange={at => setActiveTab(at)}
      />
    );
  };

  return (
    <AppPage title="User tiers" icon="users">
      <AppFetcher
        model={UserTierModel}
        id={props.match.params.id}
        isIdInUrl={true}
        ref={fetcher => {
          _fetcher = fetcher;
        }}
        renderContent={record => renderContent(record)}
      />
    </AppPage>
  );
};

export default UserTierDetails;
